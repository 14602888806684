// import { GUI } from 'three'
import * as dat from "dat.gui";

// const dev = window.location.href.includes('dev') || window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168.1.162');
console.log("dev", dev);
const dev = false;

const params = {
  vidRotX: 60,
  vidRotY: -50, // 40
  vidRotZ: -5,
  mouseMoveIn: 0.15,
  vidOffX: 20, //8
  vidOffZ: 320,
  vidFac: 1.5,
  paraX: 15,
  paraY: 10,
  radius: 90,
  speed: 0.1,
  // vidRotX: 60,
  // vidRotY: -50, // 40
  // vidRotZ: -5,
  // mouseMoveIn: 0.15,
  // vidOffX: 20, //8
  // vidOffZ: 320,
  // vidFac: 1.5,
  // paraX: 15,
  // paraY: 10,
  frequencey: 18,
  letterWidth: 20,
  letterHeight: 200,
  sinusConst: 0,
  dotOffsetYMax: 50,
  strength: 0.5,
  animateDotOffsetY: true,
  dotOffsetYSpeed: 2.2,
  dotOffsetY: 44,
  clickStrength: 1.5,
  clickDelay: 150,
  scrollStrength: 0.7,
  // // dot: true,
  // fx: 'radial',
  hsl: false,
  logo: !dev,
  soundOn: true,
  soundStrength: 1,
  idleSoundStrength: 0.5,
  touchDelay: 800,
  touchTiming: 0.5,
  fontSize: 6,
  faveDrag: true,
  playVideos: true,
  rewindOnPlay: false,
  shader: true,
  shaderOpacity: 80,
  stacksAutoplay: true,
  pauseRender: false,
  scrollHeight: 95,
  seed: 0.22,
  amount: 0,
  byp: 0,
  seed_x: 0,
  seed_y: 0,
  distortion_x: 0,
  distortion_y: 0,
  angle: 1,
  renderPosition: false,
};

var gui = 0;

if (dev && false) {
  gui = new dat.GUI();

  // gui.add( params, 'clickStrength', 0, 6, 0.05 ).name( 'clickStrength' );
  // gui.add( params, 'clickDelay', 0, 1000, 50 ).name( 'clickDelay' );
  // gui.add( params, 'scrollStrength', 0, 2, 0.05 ).name( 'scrollStrength' );
  //
  // gui.add( params, 'soundOn' ).name( 'soundOn' );
  // gui.add( params, 'soundStrength', 0, 3, 0.05 ).name( 'soundStrength' );
  // gui.add( params, 'idleSoundStrength', 0, 1, 0.05 ).name( 'idleStrength + sound' );
  //
  // gui.add( params, 'touchDelay', 0, 6000, 100 ).name( 'touchDelay (ms)' );
  // gui.add( params, 'touchTiming', 0, 5, 0.05 ).name( 'touchTiming (s)' );

  gui.add(params, "logo").name("Logo Animation");
  // gui.add(params, "playVideos").name("Videos in Scroll");
  // gui.add(params, "rewindOnPlay").name("Rewind on Play");
  // gui.add(params, 'shader').name('Shader')
  // gui.add(params, "shaderOpacity", 0, 255, 1).name("Shader Opacity");
  // gui.add(params, "faveDrag").name("Fave Drag");
  // gui.add( params, 'vidRotX', -140, 140, 1 ).name( 'Vid rotation X' );
  // gui.add( params, 'vidRotY', -90, 90, 1 ).name( 'Vid rotation Y' );
  // gui.add( params, 'vidRotZ', -90, 90, 0.5 ).name( 'Vid rotation Z' );
  // gui.add( params, 'vidOffX', 0, 30, 1 ).name( 'Vid offset X' );
  // gui.add( params, 'vidOffZ', 0, 500, 1 ).name( 'Vid offset Z' );
  // gui.add( params, 'vidFac', 1, 5, 0.1 ).name( 'Vid between fac' );
  // gui.add( params, 'mouseMoveIn', 0, 0.3, 0.01 ).name( 'Mouse influence' );
  // gui.add( params, 'paraX', 0, 100, 1 ).name( 'paraX' );
  // gui.add( params, 'paraY', 0, 100, 1 ).name( 'paraY' );

  // gui.add(params, 'stacksAutoplay').name('stacksAutoplay')

  // gui.add(params, 'pauseRender').name('dev pause render')

  //dev
  // gui.add(params, 'scrollHeight', 1, 200, 1).name('scrollHeight')

  // gui.add(params, 'seed', -2, 2, 0.01).name('seed')
  // gui.add(params, 'amount', 0, 2, 0.1).name('amount')
  // // gui.add(params, 'byp', -2, 2, 0.1).name('byp')
  // gui.add(params, 'seed_x', -2, 2, 0.1).name('seed_x')
  // gui.add(params, 'seed_y', -2, 2, 0.1).name('seed_y')
  // gui.add(params, 'distortion_x', -2, 2, 0.1).name('distortion_x')
  // gui.add(params, 'distortion_y', -2, 2, 0.1).name('distortion_y')
  // gui.add(params, 'angle', -1, 1, 0.1).name('angle')

  // gui.add(params, 'renderPosition').name('renderPosition')
}

if (params.playVideos) {
  params.playVideos = false;
  setTimeout(function () {
    params.playVideos = true;
  }, 1000);
}

export { params, gui };
