import unitsManager from './unitsManager'
import { windowManager } from '../globals/globals'

const updateResizeManager = function () {
  if (window.allStacks) {
    window.allStacks.forEach(function (stack) {
      stack[1].children.forEach(function (tile, i) {
        let g = tile.geometry.parameters
        let regScale = 1
        let playScale = Math.min(
          unitsManager.maxVirtWidth / g.width - regScale,
          unitsManager.scaleVirtHeight / g.height - regScale
        )
        tile.regScale = regScale
        tile.playScale = playScale
        i == stack[1].curTop && unitsManager.faveOffset(stack[1], tile)
      })
    })
    resizeStackTileTitles(window.allStacks)
    resizeWorkTable()
  }
}

function resizeStackTileTitles(stacks) {
  document.querySelectorAll('.proj > div').forEach(function (a) {
    a.style.fontSize =
      (windowManager.iw < 800 ? 21 : 24) / (windowManager.ih * 0.00115) + 'px'
  })

  setTimeout(function () {
    document.querySelectorAll('.proj > div').forEach(function (a) {
      a.style.fontSize =
        (windowManager.iw < 800 ? 21 : 24) / (windowManager.ih * 0.00115) + 'px'
    })

    stacks.forEach(function (stack) {
      stack = stack[0]

      let setInvisible = 0
      if (!stack.visible) {
        stack.visible = true
        setInvisible = 1
        // w.renderer = [renderer, scene, cam];
        window.renderer[0][1].render(window.renderer[1][1], window.renderer[2])
      }
      let titleHolder = stack.element.querySelector('.proj-title')
      let titleTitle = stack.element.querySelector('.tile-title')
      let projecTitle = stack.element.querySelector('.title')
      if (titleTitle) {
        let sp = titleTitle.querySelector('.sp')
        let span = titleTitle.querySelector('.uv:nth-child(2)')

        let tileTitleW = sp && span ? sp.offsetWidth + span.offsetWidth : 0
        let projectTitleW = projecTitle.offsetWidth
        let doOffset =
          tileTitleW + projectTitleW <
          Math.min(window.innerWidth - 40, titleHolder.offsetWidth)

        let offset = doOffset ? (projectTitleW - tileTitleW) / 2 : 0

        titleTitle.style.transform = `translate3d(${
          offset
            ? offset + 'px'
            : '-' + (span.offsetWidth / 2 + sp.offsetWidth) + 'px'
        },${offset ? 0 : '1.2em'},0)`
        projecTitle.style.transform = `translate3d(${
          offset ? offset + 'px' : 50 + '%'
        },0,0)`

        titleTitle.classList[offset ? 'remove' : 'add']('dwn')
        sp.classList[offset ? 'remove' : 'add']('hd')

        if (setInvisible) {
          stack.visible = false
          setInvisible = 0
        }
      }
    })
  }, 200)
}

function resizeWorkTable() {
  const widths = []
  document.querySelectorAll('.tbl .hv span').forEach(function (span, i) {
    widths.push(span.offsetWidth)
  })
  const maxw = Math.max(...widths) + 100
  if (maxw) {
    // console.log('max width'.maxw)
    document.querySelectorAll('.tbl .hv').forEach(function (p) {
      p.style.width = maxw + 'px'
    })
  }
}

const resizeManager = {
  resize: updateResizeManager,
  resizeStackTileTitles: resizeStackTileTitles,
  resizeWorkTable: resizeWorkTable
}

export default resizeManager
