import originalPath from '../utils/originalPath'

var prevRandom = ''

function randomiseFont() {
  var a = ''
  for (let i = 0; i < Math.floor(Math.random() * 5); i++) {
    a += ['&#8203;', '&#8204;'][Math.floor(Math.random() * 2)]
  }
  return prevRandom != a ? ((prevRandom = a), a) : randomiseFont()
}

function btn(aria, label, classes, id) {
  let r = randomiseFont()
  classes = classes ? ' ' + classes : ''
  return `<span aria-label="${aria}" class="uv${classes}">&nbsp;<span><i>${r}${label}${r}</i></span><span><i>${r}${label}${r}</i></span></span>`
}

function btnPlay(project) {
  let video = project.v[0].video,
    vids = 0,
    imgs = 0
  project.v.forEach(function (v) {
    if (v.video) {
      vids++
    } else {
      imgs++
    }
  })
  let r = randomiseFont()
  return `<span class="play-view">${
    (vids ? btn('play', 'Play', 'pl' + (video ? '' : ' hd')) : '') +
    (imgs ? btn('view', 'View', 'vw' + (video ? ' hd' : '')) : '')
  }
    </span>`
}

// holding colon
function titleSep(u) {
  return `<span class="uv uvv${
    u ? '' : ' hd'
  } sp">&nbsp;&mdash;&nbsp;<span><i>&nbsp;&mdash;&nbsp;</i></span><span><i>&nbsp;&mdash;&nbsp;</i></span></span>`
}

function mainTitle(t, feat = 0) {
  return `<span class="title uv ${
    feat ? 'fuv' : 'uvv'
  }">${t}<span><i>${t}</i></span><span><i>${t}</i></span></span>`
}

function tileTitle(project) {
  let t = project.v[0].title
  if (t) {
    return `<span class="tile-title${
      project.v.length > 1 ? '' : ' sg'
    }">${titleSep(1)}<span class="uv ${
      project.y == 1 ? 'fuv' : 'uvv'
    }">${t}<span><i>${t}</i></span><span><i>${t}</i></span></span></span>`
  } else {
    if (project.v.length > 1) {
      return `<div class="tile-title">${titleSep(0)}</div>`
    } else {
      return ''
    }
  }
}

function btnTitle(project) {
  return `<div class="proj-title">${
    mainTitle(project.t, project.y == 1) + tileTitle(project)
  }</div>`
}

function stackDom(project, z) {
  return (
    (project.y == 1 || originalPath.shared
      ? ''
      : `<a class="fave${
          window.faves && window.faves.includes(project.v[0].k) ? ' ac' : ''
        }" data-f="${project.v[0].k}"></a>`) +
    (project.i
      ? '<div class="info"><span></span><div>' +
        project.i +
        '</div><span></span>'
      : '') +
    `</div><div class="btm${project.y == 1 ? ' feat' : ''}"><div><div class="${
      project.y == 1 ? 'fuv' : ''
    }">` +
    (project.y == 1 ? '' : btnPlay(project)) +
    (project.i ? btn('info', 'Info') : '') +
    (project.v.length > 1 ? btn('next', 'Next') : '') +
    '</div>' +
    btnTitle(project) +
    (project.i ? `<div>${btn('close', 'Close')}</div>` : '') +
    '</div></div>'
  )
}

export default stackDom
