function playPromise(promise, tile, from = 0, video) {
  tile.playStatus = 1
  // console.log("playPromise", video.readyState);
  if (promise !== undefined) {
    promise
      .then(function () {
        // console.log('play success', 'from ' + from)
        tile.playStatus = 1
        tile.rejects = 0
        if (video.paused) {
          // console.log('wrongly paused', 'from ' + from)
          // playPromise(video.play(), tile, "wrongly paused", video);
        }
      })
      .catch(function (error) {
        // console.log('play rejected', 'from ' + from, error)
        tile.playStatus = 0
      })
  } else {
    // console.log('play promise undefined')
  }
}

export default playPromise
