import shaderManager from '../shader/shaderManager'
import { windowManager } from '../globals/globals'
import { params } from '../setup/params'
import Stacks from '../live/stacks'

function slideOut(target) {
  var stackPos = target.dataset.p
  var toSlide = target.dataset.s

  if ((stackPos || stackPos == 0) && (toSlide || toSlide == 0)) {
    var stack = Stacks[stackPos][1]
    var curTop = stack.curTop
    if (curTop != toSlide) {
      stack.children[toSlide].slideX.x =
        90 * (Math.round(Math.random()) ? 1 : -1)
      stack.children[toSlide].slideX.y =
        40 * (Math.round(Math.random()) ? 1 : -1)
      stack.children[toSlide].slideO.o = 200
      stack.children.forEach(function (slide, i) {
        if (i != toSlide) {
          slide.slideO.o = -40
        }
      })
    }
  }
}

function slideIn(target) {
  var stackPos = target.dataset.p
  var toSlide = target.dataset.s

  shaderManager.update(1)

  if ((stackPos || stackPos == 0) && (toSlide || toSlide == 0)) {
    var stack = Stacks[stackPos][1]
    var curTop = stack.curTop
    if (curTop != toSlide) {
      stack.children[toSlide].slideX.x = 0
      stack.children[toSlide].slideY.y = 0
      stack.children[toSlide].slideO.o = 0

      stack.children.forEach(function (slide, i) {
        slide.slideO.o = 0
      })
    }
  }
}

function moveTo(target) {
  var ogPos = window.workConst + parseInt(target.dataset.p)
  var nwPos = Math.round(
    ogPos * windowManager.sp + (windowManager.ih - windowManager.sp) / 2
  )
  // + windowManager.ih * ((100 - params.scrollHeight) / 100)
  console.log('pre trigger')
  window.scroller.scrollTop = nwPos
  // window.scroller.scrollTop = Math.round(ogPos * windowManager.ih)
  console.log(
    'ogPos',
    nwPos,
    window.scroller.scrollTop,
    nwPos - window.scroller.scrollTop
  )
}

export { slideOut, slideIn, moveTo }
