function fullScreen(video) {
  console.log('attempt native full screen')
  if (video.requestFullscreen) {
    video.requestFullscreen()
    console.log('enterFullscreen')
  } else if (video.webkitRequestFullscreen) {
    video.webkitRequestFullscreen()
    console.log('webkitRequestFullscreen')
  } else if (video.msRequestFullscreen) {
    console.log('msRequestFullscreen')
    video.msRequestFullscreen()
  } else if (video.webkitEnterFullScreen) {
    document.body.appendChild(video)
    video.webkitEnterFullScreen()
    console.log('webkitEnterFullScreen')
  } else if (video.msEnterFullScreen) {
    video.msEnterFullScreen()
    console.log('msEnterFullScreen')
  }
}

export default fullScreen
