import supportManager from './supportManager'
import { mathManager, select } from '../globals/globals'
import { stackNext } from './stackManager'
import shareManager from './shareManager'
import playPromise from '../utils/playPromise'
import { parse } from 'bowser'

let controls = select('#player-controls')
let stackTitle = select('#stack-title')
let tileTitle = select('#tile-title')
let titleSep = select('#title-sep')
let playButton = select('#play-button')
let muteButton = select('#mute-button')
let nextButton = select('#next-button')
let progressBar = select('#progress-bar')
let seek = select('#seek')
let seekTooltip = select('#seek-tooltip')
let timeElapsed = select('#elapsed')
let timeDuration = select('#duration')
let circle = select('#circle')

const changeSource = function (source, tile = 0) {
  console.log('changeSource', source, tile ? 1 : 0)
  if (tile || source) videoManager.stack = (tile || source)['stack']
  if (
    videoManager.stack &&
    videoManager.stack.children &&
    videoManager.stack.children.length > 1
  ) {
    nextButton.classList.remove('hd')
  } else {
    nextButton.classList.add('hd')
  }

  console.log('videoManager.stack', videoManager.stack)

  if (videoManager.stack && videoManager.stack.children) {
    videoManager.stack.children.forEach(function (cTile, x) {
      if (cTile.playStatus && x != videoManager.stack.curTop) {
        if (cTile.video) {
          cTile.video.pause()
        }
        cTile.playStatus = 0
      }
      if (cTile.video && x != videoManager.stack.curTop) {
        cTile.video.volume = 0
        cTile.video.muted = true
      }
    })
  }
  if (videoManager.video) {
    ;['timeupdate', 'play', 'pause'].forEach((event) => {
      videoManager.video.removeEventListener(
        event,
        videoManager.events[event],
        supportManager.passive
      )
    })
    ;['loadedmetadata', 'durationchange'].forEach((event) => {
      videoManager.video.removeEventListener(
        event,
        videoManager.updateState,
        supportManager.passive
      )
    })
  }
  videoManager.video && (videoManager.video.loop = true)
  videoManager.video = source
  videoManager.tile = tile
  if (source) {
    if (!videoManager.muted) {
      source.muted = false
      source.volume = videoManager.volume
    }
    playVideo(source)
  }
  cancelEnded()
  if (videoManager.video) {
    console.log('project link', shareManager.p(videoManager.video.stack))
    console.log(
      'single link',
      shareManager.t(
        videoManager.stack.children.length > 1 ? videoManager.video.plane : 0
      )
    )
    videoManager.video.loop = false
    videoManager.updateTime()
    videoManager.updateState()
    videoManager.updateMute()
    // videoManager.play();
    ;['timeupdate', 'play', 'pause', 'ended'].forEach((event) => {
      videoManager.video.addEventListener(
        event,
        videoManager.events[event],
        supportManager.passive
      )
    })
    ;['loadedmetadata', 'durationchange'].forEach((event) => {
      videoManager.video.addEventListener(
        event,
        videoManager.updateState,
        supportManager.passive
      )
    })
    videoManager.play()
    controls.classList.remove('off')
  }
  if (tile.image) {
    console.log('project link', shareManager.p(tile.stack))
    console.log(
      'single link',
      shareManager.t(tile.stack.children.length > 1 ? tile : 0, 1)
    )
    // controls.setAttribute('data-s', 'paused')
    setControls(1)
    controls.classList.add('off')
    nonVideoTimeout(tile.stack)
  }
  videoManager.updateTitles()
}

// Helpers

function formatTime(seconds) {
  const result = new Date(seconds * 1000).toISOString().substr(11, 8)
  var m = result.substr(3, 2),
    s = result.substr(6, 2),
    m = [m, parseInt(m)],
    s = [s, parseInt(s)]
  return {
    m: m[0],
    s: s[0],
    t: (m[1] ? m[1] + ':' : '') + (m[1] && s[1] < 10 ? '0' : '') + s[1]
  }
}

// Methods

const playVideo = function () {
  console.log('videoManager play fired')
  if (
    videoManager.video &&
    (videoManager.video.paused ||
      videoManager.video.ended ||
      videoManager.video.fauxEnded)
  ) {
    // playPromise(videoManager.video.play());
    var playPromise = videoManager.video.play()
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          // Automatic playback started!
        })
        .catch(function (error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        })
    }
  }
}

const pauseVideo = function () {
  console.log('pause pauseVideo')
  videoManager.video && videoManager.video.pause()
}

const togglePlay = function () {
  if (
    videoManager.video.paused ||
    videoManager.video.ended ||
    videoManager.video.fauxEnded
  ) {
    console.log('play togglePlay')
    videoManager.play()
  } else {
    console.log('pause togglePlay')
    videoManager.pause()
  }
}

function setControls(i) {
  let attrs = ['playing', 'paused', 'ended']
  // controls.setAttribute('data-s', attrs[0])
  controls.dataset.s = attrs[i]
  document.querySelector(`#${i ? 'pause' : 'play'}`).classList.remove('uvv')
  document.querySelector(`#${i ? 'play' : 'pause'}`).classList.add('uvv')
}

const updateState = function () {
  console.log('updateState')

  videoManager.timeout && clearTimeout(videoManager.timeout)

  // update play state
  if (videoManager.video.ended || videoManager.video.fauxEnded) {
    // controls.setAttribute('data-s', 'ended')
    setControls(2)
  } else if (videoManager.video.paused) {
    // controls.setAttribute('data-s', 'paused')
    setControls(1)
  } else {
    // controls.setAttribute('data-s', 'playing')
    setControls(0)
  }

  // update duration
  if (videoManager.video.duration) {
    let duration = videoManager.video.duration
    // update bar & seek max
    seek.setAttribute('max', duration)
    progressBar.setAttribute('max', duration)
    // update video duration
    const time = formatTime(mathManager.round(duration))
    // timeDuration.innerText = time.t;

    // timeDuration.querySelectorAll('u').forEach(function (u) {
    //   u.dataset.u = time.t
    // })
    // timeDuration.querySelector('span').innerHTML = time.t
    // timeDuration.setAttribute('datetime', `${time.m}m ${time.s}s`)
    if (videoManager.duration != time.t) {
      timeDuration.setAttribute('datetime', `${time.m}m ${time.s}s`)
      btn(time.t, timeDuration.querySelector('.d0'))
    }
  }
}

const updateProgress = function (end = 0) {
  let currentTime = videoManager.video[end ? 'duration' : 'currentTime']
  seek.value = currentTime
  progressBar.value = currentTime
}

function btn(text, parent = 0, width = 0, add = 0) {
  let span = document.createElement('span')
  span.classList.add('uv')
  controls.classList.contains('akk') && span.classList.add('uf')
  span.innerHTML = `${text}<span><i>${text}</i></span><span><i>${text}</i></span>`
  if (parent && parent.store != text) {
    parent.store = text
    parent.querySelectorAll('.uv:not(.nr)').forEach(function (uv) {
      uv.classList.remove('uvv')
      setTimeout(function () {
        uv.remove()
      }, 500)
    })
    if (!width) {
      parent.appendChild(span)
      setTimeout(function () {
        span.classList.add('uvv')
      }, 50)
    }
    parent.style.width = width
      ? 0
      : (['0', '2', '3', '4', '5', '6', '7', '8', '9'].includes(text)
          ? 14 + add
          : span.offsetWidth + 1 + add) + 'px'
  }
}

const updateTime = function (end = 0) {
  videoManager.video.fauxEnded = 0
  // let currentTime = videoManager.video[end ? 'duration' : 'currentTime']
  const time = formatTime(
    mathManager.round(videoManager.video[end ? 'duration' : 'currentTime'])
  )
  if (videoManager.elapsed != time.t) {
    videoManager.elapsed = time.t
    timeElapsed.setAttribute('datetime', `${time.m}m ${time.s}s`)
    let timeArray = time.m.split('').concat(time.s.split(''))
    timeArray = timeArray.reverse()
    timeArray.forEach(function (s, i) {
      btn(
        timeArray[i],
        timeElapsed.querySelector(`.p` + i),
        (i == 1 && parseFloat(time.s) < 10 && !parseFloat(time.m)) ||
          (i == 2 && !parseFloat(time.m)) ||
          (i == 3 && parseFloat(time.m) < 10)
      )
    })
    if (time.m != '00') {
      timeElapsed.querySelector('.d0').style.width = '6px'
      timeElapsed.querySelector('.d0 .uv') &&
        timeElapsed.querySelector('.d0 .uv').classList.add('uvv')
    } else {
      timeElapsed.querySelector('.d0').style.width = 0
      timeElapsed.querySelector('.d0 .uv') &&
        timeElapsed.querySelector('.d0 .uv').classList.remove('uvv')
    }
  }
}

function skipAhead(event) {
  const skipTo = event.target.dataset.seek
    ? event.target.dataset.seek
    : event.target.value
  videoManager.video.currentTime = skipTo
  progressBar.value = skipTo
  seek.value = skipTo
}
seek.addEventListener('input', skipAhead)

// Events

const playEvent = function (e) {
  cancelEnded()
  console.log('playEvent', e)
  updateState(e.target)
}
const pauseEvent = function (e) {
  cancelEnded()
  console.log('pauseEvent', e)

  if (videoManager.video.currentTime == videoManager.video.duration) {
    console.log('FALLBACK ended from pause event')
    videoManager.video.fauxEnded = true
    endedEvent()
    updateTime(1)
  }
  updateState(e.target)
}
const timeupdateEvent = function (e) {
  // console.log("timeupdateEvent", e.target.currentTime);
  cancelEnded()
  if (e.target.currentTime > e.target.duration - 2) {
    videoManager.endedTimeout = setTimeout(function () {
      console.log('FALLBACK Stream Jumping Gap Ended Event')
      let e = { target: videoManager.video }
      videoManager.video.fauxEnded = true
      endedEvent()
      updateTime(1)
    }, (e.target.duration - e.target.currentTime) * 1000)
  }
  updateTime()
}
const endedEvent = function (e) {
  // console.log("endedEvent", e ? e : 0);
  videoManager.isEnded = 1
  videoManager.nextWght = 0
  updateTime()
  updateState()
  videoManager.endedTimeout && clearTimeout(videoManager.endedTimeout)
  videoManager.endedTimeout = setTimeout(function () {
    console.log('go next')
    if (videoManager.video.stack.children.length > 1) {
      cancelEnded()
      stackNext(videoManager.video.stack, 0)
    } else {
      videoManager.currentTime = 0
      videoManager.play()
    }
  }, 3000)
}

const nonVideoTimeout = function (stack) {
  cancelEnded()
  videoManager.imageTimeout = setTimeout(function () {
    cancelEnded()
    videoManager.isEnded = 1
    videoManager.endedTimeout = setTimeout(function () {
      if (stack.children.length > 1) {
        cancelEnded()
        stackNext(stack, 0)
      }
    }, 3000)
  }, 2000)
}

const cancelEnded = function () {
  videoManager.endedTimeout && clearTimeout(videoManager.endedTimeout)
  videoManager.imageTimeout && clearTimeout(videoManager.imageTimeout)
  videoManager.isEnded = 0
  videoManager.nextWght = 0
  if (
    nextButton.classList.contains('running') &&
    !nextButton.classList.contains('cancelled')
  ) {
    nextButton.classList.add('cancelled')
    setTimeout(function () {
      nextButton.classList.remove('running')
      nextButton.classList.remove('cancelled')
    }, 500)
  }
}

nextButton.addEventListener('mouseenter', function () {
  cancelEnded()
})

nextButton.addEventListener('click', function () {
  cancelEnded()
  if (videoManager.tile) {
    stackNext(videoManager.tile.stack, 0)
  } else {
    stackNext(videoManager.video.stack, 0)
  }
})

const runEnded = function (wght) {
  videoManager.nextWght = videoManager.nextWght + wght
  nextButton.classList.add('running')
  nextButton.style = '--w:' + videoManager.nextWght
}

const toggleMute = function () {
  console.log('toggle muted', 'muted: ' + videoManager.video.muted)
  if (videoManager.muted) {
    videoManager.muted = 0
    videoManager.video.muted = false
    videoManager.video.volume = videoManager.maxVolume
  } else {
    videoManager.muted = 1
    videoManager.video.volume = 0
    videoManager.video.muted = true
  }
  videoManager.updateMute()
}

const updateMute = function () {
  let m = videoManager.muted
  muteButton.dataset.m = m ? 1 : 0
  document.querySelector(`#${m ? '' : 'un'}mute`).classList.remove('uvv')
  document.querySelector(`#${m ? 'un' : ''}mute`).classList.add('uvv')
}

const updateTitles = function () {
  videoManager.titleTimeout && clearTimeout(videoManager.titleTimeout)
  if (videoManager.video || videoManager.tile) {
    let tile = videoManager.video ? videoManager.video.plane : videoManager.tile
    console.log('stack title', tile.stack.title)
    console.log('tile title', tile.title)
    console.log('videoManager.tile', videoManager.tile)

    // stackTitle.querySelectorAll('u').forEach(function (u) {
    //   u.dataset.u = tile.stack.title.replace('&#038;', '&')
    // })
    // stackTitle.querySelector('span').innerHTML = tile.stack.title

    btn(tile.stack.title, stackTitle)

    // if (tileTitle.querySelectorAll('div').length) {
    //   tileTitle.querySelectorAll('div').forEach(function (a) {
    //     a.classList = 'hd'
    //   })
    //   videoManager.titleTimeout = setTimeout(function () {
    //     tileTitle.querySelectorAll('div').forEach(function (a) {
    //       if (!a.classList.contains('ac')) {
    //         tileTitle.removeChild(a)
    //       }
    //     })
    //   }, 500)
    // }

    if (tile.title) {
      // var title = document.createElement('div')
      // title.classList = 'ac hd'
      // title.dataset.x = '1'
      // title.innerHTML =
      //   '<u data-u="' +
      //   tile.title +
      //   '"></u><u data-u="' +
      //   tile.title +
      //   '"></u>' +
      //   tile.title
      // tileTitle.appendChild(title)
      // tileTitle.style.width = title.clientWidth + 17 + 'px'
      // setTimeout(function () {
      //   title.classList = 'ac'
      btn(tile.title, tileTitle, 0, 28)
      titleSep.classList.add('uvv')
      // }, 50)
    } else {
      btn(false, tileTitle)
      // tileTitle.style.width = '0px'
      titleSep.classList.remove('uvv')
    }
  }
}

const videoManager = {
  video: 0,
  timeout: 0,
  endedTimeout: 0,
  imageTimeout: 0,
  titleTimeout: 0,
  isEnded: 0,
  muted: 0,
  volume: 0,
  maxVolume: 0.5,
  source: changeSource,
  play: playVideo,
  pause: pauseVideo,
  togglePlay: togglePlay,
  updateState: updateState,
  updateTime: updateTime,
  updateProgress: updateProgress,
  runEnded: runEnded,
  toggleMute: toggleMute,
  updateMute: updateMute,
  updateTitles: updateTitles,
  nextWght: 0,
  events: {
    play: playEvent,
    pause: pauseEvent,
    timeupdate: timeupdateEvent,
    ended: endedEvent
  },
  elapsed: 0
}

// Attach methods to DOM controls

playButton.addEventListener(
  'click',
  videoManager.togglePlay,
  supportManager.passive
)

muteButton.addEventListener(
  'click',
  videoManager.toggleMute,
  supportManager.passive
)

export default videoManager
