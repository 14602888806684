import { WEBGL } from 'three/examples/jsm/WebGL'
import Bowser from 'bowser'
import { supportsPassiveEvents } from 'detect-passive-events'

const browserParams = {
  unknown: {
    antialias: true,
    maxDPR: 1.25
  },
  safari: {
    antialias: false,
    maxDPR: 2
  }
}

// support manager
var userAgent = navigator.userAgent
const html = document.querySelector('html') || document.documentElement

let passiveEvents = false
if (supportsPassiveEvents) {
  passiveEvents = { capture: false, passive: true }
}

function getiPhoneModel() {
  // Create a canvas element which can be used to retrieve information about the GPU.
  var canvas = document.createElement('canvas')
  if (canvas) {
    var context =
      canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
    if (context) {
      var info = context.getExtension('WEBGL_debug_renderer_info')
      if (info) {
        var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL)
      }
    }
  }

  // iPhone X
  if (
    window.screen.height / window.screen.width == 812 / 375 &&
    window.devicePixelRatio == 3
  ) {
    return 'iPhone X'
    // iPhone 6+/6s+/7+ and 8+
  } else if (
    window.screen.height / window.screen.width == 736 / 414 &&
    window.devicePixelRatio == 3
  ) {
    switch (renderer) {
      default:
        return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus'
      case 'Apple A8 GPU':
        return 'iPhone 6 Plus'
      case 'Apple A9 GPU':
        return 'iPhone 6s Plus'
      case 'Apple A10 GPU':
        return 'iPhone 7 Plus'
      case 'Apple A11 GPU':
        return 'iPhone 8 Plus'
    }
    // iPhone 6+/6s+/7+ and 8+ in zoom mode
  } else if (
    window.screen.height / window.screen.width == 667 / 375 &&
    window.devicePixelRatio == 3
  ) {
    switch (renderer) {
      default:
        return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus (display zoom)'
      case 'Apple A8 GPU':
        return 'iPhone 6 Plus (display zoom)'
      case 'Apple A9 GPU':
        return 'iPhone 6s Plus (display zoom)'
      case 'Apple A10 GPU':
        return 'iPhone 7 Plus (display zoom)'
      case 'Apple A11 GPU':
        return 'iPhone 8 Plus (display zoom)'
    }
    // iPhone 6/6s/7 and 8
  } else if (
    window.screen.height / window.screen.width == 667 / 375 &&
    window.devicePixelRatio == 2
  ) {
    switch (renderer) {
      default:
        return 'iPhone 6, 6s, 7 or 8'
      case 'Apple A8 GPU':
        return 'iPhone 6'
      case 'Apple A9 GPU':
        return 'iPhone 6s'
      case 'Apple A10 GPU':
        return 'iPhone 7'
      case 'Apple A11 GPU':
        return 'iPhone 8'
    }
    // iPhone 5/5C/5s/SE or 6/6s/7 and 8 in zoom mode
  } else if (
    window.screen.height / window.screen.width == 1.775 &&
    window.devicePixelRatio == 2
  ) {
    switch (renderer) {
      default:
        return 'iPhone 5, 5C, 5S, SE or 6, 6s, 7 and 8 (display zoom)'
      case 'PowerVR SGX 543':
        return 'iPhone 5 or 5c'
      case 'Apple A7 GPU':
        return 'iPhone 5s'
      case 'Apple A8 GPU':
        return 'iPhone 6 (display zoom)'
      case 'Apple A9 GPU':
        return 'iPhone SE or 6s (display zoom)'
      case 'Apple A10 GPU':
        return 'iPhone 7 (display zoom)'
      case 'Apple A11 GPU':
        return 'iPhone 8 (display zoom)'
    }
    // iPhone 4/4s
  } else if (
    window.screen.height / window.screen.width == 1.5 &&
    window.devicePixelRatio == 2
  ) {
    switch (renderer) {
      default:
        return 'iPhone 4 or 4s'
      case 'PowerVR SGX 535':
        return 'iPhone 4'
      case 'PowerVR SGX 543':
        return 'iPhone 4s'
    }
    // iPhone 1/3G/3GS
  } else if (
    window.screen.height / window.screen.width == 1.5 &&
    window.devicePixelRatio == 1
  ) {
    switch (renderer) {
      default:
        return 'iPhone 1, 3G or 3GS'
      case 'ALP0298C05':
        return 'iPhone 3GS'
      case 'S5L8900':
        return 'iPhone 1, 3G'
    }
  } else {
    return 0
  }

  canvas.parentNode.removeChild(canvas)
}

var iPhoneModel = getiPhoneModel()

var browserBase

if (userAgent.indexOf('Firefox') > -1) {
  browserBase = 'firefox'
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
} else if (userAgent.indexOf('SamsungBrowser') > -1) {
  browserBase = 'samsung'
  // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
} else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
  browserBase = 'opera'
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
} else if (userAgent.indexOf('Trident') > -1) {
  browserBase = 'ie'
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
} else if (userAgent.indexOf('Edge') > -1) {
  browserBase = 'edge'
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
} else if (userAgent.indexOf('Chrome') > -1) {
  browserBase = 'chrome'
  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
} else if (userAgent.indexOf('Safari') > -1) {
  browserBase = 'safari'
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
} else {
  browserBase = 'unknown'
}

const browser = Bowser.getParser(window.navigator.userAgent)

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

function cloudflareID(mode) {
  if (!mode) {
    //img
  } else if (mode == 1) {
    //dash
  } else {
    //hls
  }
}

const supportManager = {
  webgl: WEBGL.isWebGLAvailable(),
  base: browserBase,
  browser: browser.parsedResult,
  model: { iPhone: iPhoneModel },
  iOS: iOS(),
  native: browser.parsedResult.platform.type == 'mobile',
  passive: passiveEvents,
  touch:
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
}

// supportManager.touch = true

let desktopPixelRatio =
  supportManager.base == 'chrome' &&
  supportManager.browser.platform.type == 'desktop'
    ? 1.25
    : supportManager.base == 'safari'
    ? 1.25
    : 1

let pixelRatio = Math.min(
  window.innerWidth < 851 ? 1.75 : desktopPixelRatio,
  window.devicePixelRatio
)
supportManager.pixelRatio = pixelRatio

let antialias = supportManager.base != 'safari' || true
supportManager.antialias = antialias

// let depthBuffer =
//   supportManager.base == 'safari' &&
//   supportManager.browser.platform.type == 'desktop' &&
//   !supportManager.antialias
// supportManager.depthBuffer = depthBuffer
supportManager.depthBuffer = false

// console.log("Browser base", supportManager.base);
// console.log("Browser", supportManager.browser);
// console.log("Model", supportManager.model);
// console.log("iOS", supportManager.iOS);
// console.log("WEBGL passed", supportManager.webgl);
// console.log("native playback", supportManager.native);
// console.log("depth buffer", supportManager.depthBuffer);
// console.log("pixel ratio", supportManager.pixelRatior);
// console.log("pixel ratio", supportManager.pixelRatior);

console.log('supportManager', supportManager)

// navigator.sayswho = (function () {
//   var ua = navigator.userAgent,
//     tem,
//     M =
//       ua.match(
//         /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
//       ) || [];
//   if (/trident/i.test(M[1])) {
//     tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
//     return "IE " + (tem[1] || "");
//   }
//   if (M[1] === "Chrome") {
//     tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
//     if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
//   }
//   M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
//   if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
//   return M.join(" ");
// })();

// console.log("Who dis", navigator.sayswho); // outputs: `Chrome 62`

if (!supportManager.webgl) {
  console.log('!! WEBGL context failed, check chrome://gpu')
  const warning = WEBGL.getWebGLErrorMessage()
  document.body.appendChild(warning)
}

html.classList.add(supportManager.touch ? 'touch' : 'no-touch')

export default supportManager
