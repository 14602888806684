import videoManager from './videoManager'
import unitsManager from './unitsManager'
import scaleVolManager from './scaleVolManager'
import shaderManager from '../shader/shaderManager'
import Stacks from '../live/stacks'

var w = window
var stackNext

var multStackManager = {}

function stackManager(
  stacksDOM,
  stacks,
  TweenMax,
  Power3,
  iw,
  vw,
  vh,
  max,
  playPromise,
  params
) {
  stacksDOM.forEach(function (a, i) {
    if (a.mv) {
      a.addEventListener('touchstart', stackStart, { passive: true })
      a.addEventListener('mousedown', stackStart)
    } else {
      a.addEventListener('click', stackClick)
    }
    a.addEventListener('mouseenter', mouseEnter)
  })

  function stackStart(e, t, l) {
    // Start Move
    multStackManager.p = e.target
    l = e.target
    t = e.type.includes('uc')
    if (t) l.removeEventListener('mousedown', stackStart)

    l.eType = t
    w.stackMv = [e.pageX, e.pageY]
    w.stackMvd = 0

    w.stackIx = l.ix ? l.ix : 0

    // console.log("start move stack", w.stackIx);

    w.stackEl = e.target

    w.addEventListener((t ? 'touch' : 'mouse') + 'move', stackMove)
    w.addEventListener(t ? 'touchend' : 'mouseup', stackEnd)
  }

  function stackMove(e, l) {
    if (w.stackIx) {
      var curStack = stacks[w.stackIx - 1][1]
      curStack.children[curStack.curTop].ogCoords.x =
        curStack.children[curStack.curTop].ogX.x -
        ((w.stackMv[0] - e.pageX) / iw) * 100 * vw
      curStack.children[curStack.curTop].ogCoords.y =
        curStack.children[curStack.curTop].ogY.y +
        (((w.stackMv[1] - e.pageY) / iw) * 100 * vw) / 2
    }

    w.stackMvd = w.stackMvd + 1
  }

  function stackEnd(e, click = 0, x, y, ix) {
    let target = multStackManager.p || e.target,
      type = target.eType,
      btn = target.getAttribute('aria-label')

    if (target.classList.contains('fave')) {
      btn = 'fave'
      // target.dispatchEvent(new Event('click'))
    }

    // console.log('target', target)

    for (let i = 0; i < 9; i++) {
      if (target.classList.contains('proj')) {
        break
      } else {
        target = target.offsetParent
      }
    }

    ix = target.ix

    // console.log('click target', target, target.ix)

    var curStack = stacks[ix - 1][1]

    var cif = !target.classList.contains('if')
    var feat = target.classList.contains('feat')

    if (btn) {
      w.stackIx = ix
      if (btn == 'next') {
        stackNext(curStack, 0)
      }
      if (btn == 'info' || btn == 'close') {
        // var cif = !target.classList.contains('if')
        // w.shaderOpacity = cif
        // w.shaderStrength = cif
        // w.shaderCancel = cif
        if (cif) {
          w.rotation = 0
          shaderManager.update(1, 1)
          target.classList.add('if')
          target
            .querySelector('.btm > div > :first-child')
            .classList.remove('uvv')
          target
            .querySelector('.btm > div > :nth-child(3)')
            .classList.add('uvv')
        } else {
          w.rotation = 1
          shaderManager.update(0)
          target.classList.remove('if')
          target
            .querySelector('.btm > div > :nth-child(3)')
            .classList.remove('uvv')
          target.querySelector('.btm > div > :first-child').classList.add('uvv')
        }
        // cif ? target.classList.add('if') : target.classList.remove('if')
      }
      if (btn == 'play' || (btn == 'view' && !feat)) {
        scaleVolManager.playStack(Stacks[ix - 1])
      }
    } else if (curStack.children.length < 2 && cif && !feat) {
      if (w.playAC) {
        videoManager.togglePlay()
      } else {
        scaleVolManager.playStack(Stacks[ix - 1])
      }
      console.log('from else if else')
      // }
    } else {
      if (cif) {
        var threshHold = 100
        if (!click) {
          x = w.stackMv[0] - e.pageX
          y = w.stackMv[1] - e.pageY
        }
        if (x > threshHold) {
          stackNext(curStack, -1)
        } else if (x < -threshHold) {
          stackNext(curStack, 1)
        } else {
          ;['x', 'y'].forEach(function (a) {
            curStack.children[curStack.curTop].ogCoords[a] = 0
          })
          if (target) {
            target.classList.add('ac')
            // target.classList.add('uvv')
          }
          if (!click && w.stackMvd < 5 && !feat) {
            if (w.playAC) {
              videoManager.togglePlay()
            } else {
              scaleVolManager.playStack(Stacks[ix - 1])
            }
          }
        }
      }
    }
    // End move
    w.removeEventListener((type ? 'touch' : 'mouse') + 'move', stackMove)
    w.removeEventListener(type ? 'touchend' : 'mouseup', stackEnd)
    multStackManager.p = 0
  }

  function stackClick(e) {
    // console.log("stackClick");
    stackEnd(e, 1)
  }

  function tileTitleSwitch() {
    // console.log("switch title", this);
    this.remove()
  }

  stackNext = function (curStack, v) {
    !v && (v = [1, -1][Math.floor(Math.random() * 2)])

    var total = curStack.children.length - 1
    var curTop = curStack.curTop
    curTop = curTop + 1 > total ? 0 : curTop + 1
    curStack.curTop = curTop
    var prev = curTop - 1 < 0 ? total : curTop - 1
    var last = curTop + 1 > total ? 0 : curTop + 1

    var plane = stacksDOM[curStack.ix]

    // console.log("nextStack", curStack.children[curTop]);

    if (curStack.children.length > 1) {
      let titleHolder = plane.querySelector('.proj-title')
      let sp = plane.querySelector('.proj-title .sp')
      let titleTitle = plane.querySelector('.tile-title')
      let projectTitle = plane.querySelector('.title')
      var uvs = titleTitle.querySelectorAll('.uv')
      if (uvs) {
        uvs.forEach(function (uv) {
          if (!uv.classList.contains('sp')) {
            uv.classList.add('hd')
            uv.classList.remove('uf')
            uv.to = setTimeout(tileTitleSwitch.bind(uv), 500)
          }
        })
      }

      if (curStack.children[curTop].title) {
        let title = curStack.children[curTop].title
        sp.length && sp.classList.remove('hd')
        // titleTitle.appendChild('app');
        // console.log("nextStack Tttle", curStack.children[curTop].title);
        let span = document.createElement('span')
        span.innerHTML =
          title +
          '<span><i>' +
          title +
          '</i></span><span><i>' +
          title +
          '</i></span>'
        span.classList.add('uv')
        span.classList.add('uvv')
        span.classList.add('hd')
        span.classList.add('nd')

        titleTitle.appendChild(span)
        // span.classList.remove("hd");

        setTimeout(function () {
          span.classList.remove('hd')
          window.feat && span.classList.add('uf')
        }, 10)
        setTimeout(function () {
          span.classList.remove('nd')
        }, 500)

        let tileTitleW = sp && span ? sp.offsetWidth + span.offsetWidth : 0
        let projectTitleW = projectTitle.offsetWidth
        let doOffset =
          tileTitleW + projectTitleW <
          Math.min(window.innerWidth - 40, titleHolder.offsetWidth)

        let offset = doOffset ? (projectTitleW - tileTitleW) / 2 : 0

        titleTitle.style.transform = `translate3d(${
          offset
            ? offset + 'px'
            : '-' + (span.offsetWidth / 2 + sp.offsetWidth) + 'px'
        },${offset ? 0 : '1.2em'},0)`
        projectTitle.style.transform = `translate3d(${
          offset ? offset + 'px' : 50 + '%'
        },0,0)`

        titleTitle.classList[offset ? 'remove' : 'add']('dwn')
        sp.classList[offset ? 'remove' : 'add']('hd')

        // titleTitle.style.width =
        //   (sp.length ? sp.offsetWidth : 0) + span.offsetWidth + 'px'
        // console.log(
        //   'total w',
        //   span.offsetWidth + projectTitle.offsetWidth + sp.offsetWidth,
        //   'holder w',
        //   Math.min(titleHolder.offsetWidth, window.innerWidth - 40)
        // )
        if (
          span.offsetWidth + projectTitle.offsetWidth + sp.offsetWidth >
          Math.min(titleHolder.offsetWidth, window.innerWidth - 40)
        ) {
          titleTitle.classList.add('dwn')
        } else {
          titleTitle.classList.remove('dwn')
        }
      } else {
        sp.classList.add('hd')
        titleTitle.style.width = '0px'
      }

      stackCredits(curStack.ix)
    }
    // console.log("nextStack Ratio", curStack.children[curTop].title);

    if (curStack.children[curTop].video) {
      stacksDOM[curStack.ix].classList.remove('vw')
      stacksDOM[curStack.ix].querySelector('.vw') &&
        stacksDOM[curStack.ix].querySelector('.vw').classList.add('hd')
      stacksDOM[curStack.ix].querySelector('.pl') &&
        stacksDOM[curStack.ix].querySelector('.pl').classList.remove('hd')
    } else {
      stacksDOM[curStack.ix].classList.add('vw')
      stacksDOM[curStack.ix].querySelector('.pl') &&
        stacksDOM[curStack.ix].querySelector('.pl').classList.add('hd')
      stacksDOM[curStack.ix].querySelector('.vw') &&
        stacksDOM[curStack.ix].querySelector('.vw').classList.remove('hd')
    }

    // set faves
    // console.log("stackNext", curStack.ix);
    // console.log("faves", w.faves[curStack.ix + "-+-" + curTop]);

    var faveBtn = stacksDOM[curStack.ix].querySelector('.fave')

    if (faveBtn) {
      if (w.faves.includes(curStack.children[curTop].k)) {
        faveBtn.classList.add('ac')
      } else {
        faveBtn.classList.remove('ac')
      }

      faveBtn.dataset.f = curStack.children[curTop].k
    }

    var i
    var h = []
    for (i = 0; i < total + 1; i++) {
      h.push(i)
    }

    var i
    for (i = 0; i < curTop; i++) {
      h.unshift(h.pop())
    }

    curStack.children.forEach(function (a, i) {
      curStack.children[i].ogCoords.y = 0.1 + Math.random() + vh * h[i]
      if (prev == i) {
        curStack.children[i].ogCoords.x = 1550 * v
        clearTimeout(curStack.children[i].zTimeout1)
        curStack.children[i].zTimeout1 = setTimeout(function () {
          // console.log('h', h)
          curStack.children[i].ogCoords.x = curStack.children[i].ogX.x
        }, 100)
      } else {
        if (curTop == i) {
          curStack.children[i].ogCoords.x = 0
          // console.log(curStack.children[i]);
        } else {
          curStack.children[i].ogCoords.x = curStack.children[i].ogX.x
        }
      }

      clearTimeout(curStack.children[i].zTimeout2)
      curStack.children[i].zTimeout2 = setTimeout(function () {
        console.log('h', h)
        curStack.children[i].renderOrder = curStack.children.length - h[i]
        curStack.children[i].position.z = -1.5 * h[i]
      }, 100)

      // setTimeout(function () {
      //   console.log(h[i])
      //   curStack.children[i].position.z = -1.5 * h[i]
      // }, 100)

      //   var rgb = max(0, Math.round(255 - 63.75 * h[i]));
      //   curStack.children[i].beO.o = rgb;

      if (w.playAC) {
        let curTile = curStack.children[curStack.curTop]
        if (curTile.video) {
          curTile.video.currentTime = 0
          curTile.playStatus = 1
          console.log('source from next video')
          videoManager.source(curTile.video)
        }
        if (!curTile.video && curTile.image) {
          curTile.playStatus = 1
          console.log('source from next img')
          videoManager.source(0, curTile)
        }
      }

      var g = i - 1 < 0 ? total : i - 1
    })
  }

  w.hovTO = 0

  function mouseEnter(e) {
    w.hovTO && clearTimeout(w.hovTO)
    w.hovTO = setTimeout(function () {
      e.target.classList.add('ac')
    }, 1500)
    e.target.addEventListener('mouseleave', mouseLeave)
  }

  function mouseLeave(e) {
    w.hovTO && clearTimeout(w.hovTO)
  }

  w.shrunk = 1

  const playShrinkManager = {}
}

function stackCredits(index, altStacks = false) {
  if (Stacks && index) {
    let proj = window.AP.work[index]
    // console.log('Stacks', Stacks)
    console.log('Stacks', Stacks, index)
    let tileIx = Stacks[index][1].curTop
    for (let i = 0; i <= 5; i++) {
      let cr = document.querySelector('#cr' + (i + 1))
      let uv = false
      if (cr) {
        uv = cr.querySelector('.uvv')
      }
      if (
        uv &&
        proj &&
        proj.v &&
        proj.v[tileIx] &&
        proj.v[tileIx].creds &&
        proj.v[tileIx].creds[i]
      ) {
        let cred = proj.v[tileIx].creds[i]
        if (!uv || (uv && cred != uv.querySelector('span i').innerHTML)) {
          if (uv) {
            uv.classList.remove('uvv')
            uv.to = setTimeout(removeInactiveUv.bind(uv), 500)
          }
          let credSpan = document.createElement('span')
          credSpan.classList.add('uv')
          credSpan.classList.add('uvv')
          credSpan.classList.add('nd')
          credSpan.classList.add('hd')
          credSpan.innerHTML =
            cred +
            '<span><i>' +
            cred +
            '</i></span><span><i>' +
            cred +
            '</i></span>'
          setTimeout(function () {
            credSpan.classList.remove('hd')
          }, 100)
          setTimeout(function () {
            credSpan.classList.remove('nd')
          }, 400)
          cr.appendChild(credSpan)
        }
      } else {
        if (uv) {
          uv.classList.remove('uvv')
          uv.to = setTimeout(removeInactiveUv.bind(uv), 500)
        }
      }
    }
  }
}

function removeInactiveUv() {
  this.remove()
}

export { stackManager, stackCredits, stackNext }
