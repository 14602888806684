import supportManager from './supportManager'
import videoManager from './videoManager'
import { windowManager } from '../globals/globals'
import { params } from '../setup/params'
import { TweenMax, Power3 } from 'gsap/gsap-core'
import playPromise from '../utils/playPromise'
import fullScreen from '../utils/fullScreen'

const scaleVolManager = {}
const w = window

function clearBoxTimeouts() {
  ;[window.workbox, window.studiobox, window.sharebox].forEach(function (box) {
    clearTimeout(box.timeout)
  })
}

w.scale = { s: 0 }

function playStack(passedStack) {
  clearBoxTimeouts()
  let stack = passedStack[1],
    tile = stack.children[stack.curTop],
    video = tile.video
  if (!scaleVolManager.stack && video) {
    if (!tile.playStatus) {
      playPromise(video.play(), tile, 'playStack', video)
    }
  }
  if (!scaleVolManager.stack && !supportManager.native) {
    scaleVolManager.stack = stack
    scaleVolManager.toggle = 1
    scaleVolManager.scaled = 1
    document.body.classList.add('play')
    document.body.dataset.b = 1
    w.playAC = 1
    w.playingStack = passedStack

    if (video) {
      params.rewindOnPlay && (video.currentTime = 0)
      if (!videoManager.muted) {
        video.volume = 0
        videoManager.volume = 0
      }
      videoManager.source(video)
    } else {
      videoManager.source(0, tile)
    }
    let btnTrigger = 1
    TweenMax.to(w.scale, 1, {
      s: 1,
      ease: Power3.easeOut,
      // onStart: function () {},
      onUpdate: function () {
        stack.children.forEach(function (sTile, i) {
          let s = sTile.regScale + sTile.playScale * w.scale.s
          sTile.scale.set(s, s, 1)
        })
        if (video && w.volumeSt) {
          videoManager.volume = parseFloat(
            (w.scale.s * videoManager.maxVolume).toFixed(1)
          )
          if (!videoManager.muted) video.volume = videoManager.volume
          videoManager.updateMute()
        }
        if (w.scale.s > 0.8 && btnTrigger) {
          btnTrigger = 0
          document.querySelector('#player-controls').classList.add('akk')
          document
            .querySelectorAll('#player-controls .uv')
            .forEach(function (uv) {
              uv.classList.add('uf')
            })
          document
            .querySelectorAll(
              '#stack-title > :last-child,#tile-title > :last-child,#t-sep,#share-button > span,#next-button > span,#duration .d0 > :last-child'
            )
            .forEach(function (uv) {
              uv.classList.add('uvv')
            })
        }
      },
      onComplete: function () {
        TweenMax.killTweensOf(w.scale)
        if (scaleVolManager.scaled) {
          w.allStacks[stack.ix][0].scale.set(2, 2, 2)
          console.log('scale up')
        }
      }
    })
  }
  if (supportManager.native && video) {
    fullScreen(video)
    video.muted = false
    video.volume = videoManager.maxVolume
  }
}

function shrinkStack(pause) {
  if (scaleVolManager.stack) {
    document.body.classList.remove('play')
    let stack = scaleVolManager.stack,
      tile = stack.children[stack.curTop],
      video = tile.video
    if (!video) {
      videoManager.source(0)
    }
    if (!supportManager.native) {
      scaleVolManager.scaled && w.allStacks[stack.ix][0].scale.set(1, 1, 1)
      // pause = pause || false;
      w.playingStack = 0

      if (w.scrollConst) {
        w.allScroll = 0
        var calc = w.scrollConst + w.stackConst
        w.scrollConst = calc
        w.scroller.scrollTop = calc * windowManager.sh + windowManager.so
        w.playAC = 0
        w.allScroll = 1
        w.stackConst = 0
      } else {
        w.playAC = 0
      }
      TweenMax.killTweensOf(w.scale)
      TweenMax.to(w.scale, 1, {
        s: 0,
        ease: Power3.easeOut,
        onStart: function () {
          document.querySelector('#player-controls').classList.remove('akk')
          document
            .querySelectorAll('#player-controls .uf')
            .forEach(function (uf) {
              uf.classList.remove('uf', 'uvv')
            })
          // document
          //   .querySelectorAll('#player-controls .uvv')
          //   .forEach(function (uvv) {
          //     uvv.classList.remove('uvv')
          //     uvv.classList.add('ruv')
          //   })
        },
        onUpdate: function () {
          stack.children.forEach(function (sTile, i) {
            let s = sTile.regScale + sTile.playScale * scale.s
            sTile.scale.set(s, s, 1)
          })
          if (video && tile.playStatus && !videoManager.muted) {
            videoManager.volume = parseFloat(
              (w.scale.s * videoManager.maxVolume).toFixed(1)
            )
            video.volume = videoManager.volume
          } else {
            videoManager.volume = 0
            console.log('pre-shrunk mute')
            if (video) {
              video.volume = 0
              video.muted = true
            }
          }
        },
        onComplete: function () {
          document.body.classList.remove('play')
          scaleVolManager.stack = 0
          videoManager.source(0)
          if (video) {
            video.volume = 0
            video.muted = true
            scaleVolManager.toggle = 0
          }
          TweenMax.killTweensOf(w.scale)
        }
      })
    } else {
      scaleVolManager.stack = 0
    }
  }
}

function endFullscreen(video) {
  // video.volume = 0;
  video.muted = true
}

scaleVolManager.playStack = playStack
scaleVolManager.pauseStack = shrinkStack
scaleVolManager.endFullscreen = endFullscreen

export default scaleVolManager
