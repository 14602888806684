function cloudflareUrl(id, type, pos = 0) {
  let url = "https://videodelivery.net/" + id + "/";
  if (!type) {
    url += "thumbnails/thumbnail.jpg" + (pos ? "?time=" + pos + "s" : "");
  } else {
    url += "manifest/video." + (type == "hls" ? "m3u8" : "mpd");
  }
  return url;
}
export default cloudflareUrl;
