import {
  MathUtils,
  DataTexture,
  RGBFormat,
  FloatType,
  LuminanceFormat,
  UnsignedByteType
} from 'three'

export default function unit8Tex(side) {
  /*Data texture*/
  //   var side = 32 // power of two textures are better cause powers of two are required by some algorithms. Like ones that decide what color will pixel have if amount of pixels is less than amount of textels (see three.js console error when given non-power-of-two texture)

  var amount = Math.pow(side, 2) // you need 4 values for every pixel in side*side plane
  var data = new Uint8Array(amount)
  /* 
  You can also use any js typed array or ArrayBuffer itself
  Most popular is Uint8Array (it can contain itegers from 0 to 255)
*/
  for (var i = 0; i < amount; i++) {
    let val = Math.round(Math.random()) * 75
    if (i > 4) {
      if (data[i - 5] == val) {
        val = Math.abs(val - 75)
      }
    }
    data[i] = val
    // data[i] = val
    // data[i + 1] = val
    // data[i + 2] = val
    //   for (var i = 0; i < amount; i++) {
    //     data[i] = Math.round(Math.random()) * 255 // generates random r,g,b,a values from 0 to 1
    /*  
    When using Uint8Array multiply the random value by 255 to get same results
    'cause in that case you use integers from 0 to 255 to represent colors
    which is limiting but is more widely supported (see comment below)
  */
  }
  //   console.log(renderer.context.getExtension('OES_texture_float'))
  //   console.log(renderer.context.getExtension('OES_texture_float_linear'))
  /* 
   If you got nothing, check console to see if you have this extension
   If not: use Uint8Array instead of Float32Array and THREE.UnsignedByteType istead of 
   THREE.FloatType in texture constructor
*/
  return new DataTexture(data, side, side, LuminanceFormat, UnsignedByteType)
}
