var base = 'https://' + window.location.hostname + '/',
  link = ['work/', 'reel/', 'share/']

function stackLink(stack) {
  document.querySelector('#share-project-link').value =
    base + link[0] + stack.url
}

function singleLink(tile, type = 0) {
  if (tile) {
    document.querySelector('.share-single').classList.remove('hd')
    document.querySelector('#share-single-link').classList.remove('hd')
    document.querySelector('.share-single').dataset.t = type ? 'img' : 'vid'
    document.querySelector('#share-single-link').value = base + link[0] + tile.k
  } else {
    document.querySelector('.share-single').classList.add('hd')
    document.querySelector('#share-single-link').classList.add('hd')
  }
}

function reelLink(fave) {
  document.querySelector('#share-reel-link').value = base + link[0] + stack.url
  // return base + link[1]
}

function workLink() {
  console.log('workLink', window.faves)
  if (window.faves) {
    document.querySelector('#share-work-link').value =
      base + link[2] + window.faves.join('/')
  }
}

const shareManager = {
  p: stackLink,
  t: singleLink,
  r: reelLink,
  w: workLink
}

export default shareManager
