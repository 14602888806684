const originalPath = {};
originalPath.path = window.location.href
  .replace("/share/", "/work/")
  .replace(/https?:\/\/.+\.[a-z]+\/|\/$|^https?:\/\/localhost:[0-9]+\//, "")
  .split("/");

console.log("originalPath", originalPath);

if (/share|work|reels|artists/.test(originalPath.path[0])) {
  originalPath.shared = 1;
  window.isShared = 1;

  if (/artists/.test(originalPath.path[0])) {
    (document.querySelector("html") || document.documentElement).classList.add(
      "artist"
    );
    originalPath.light = 0;
  } else {
    (document.querySelector("html") || document.documentElement).classList.add(
      "shared"
    );
    originalPath.light = 1;
  }

  var btns = document.getElementById("btns");
  if (/reels|share|work/.test(originalPath.path[0])) {
    btns.querySelectorAll(".featured, .artists, .studio").forEach((btn) => {
      btns.removeChild(btn);
    });
    document.querySelector(".menu").innerHTML =
      "Exit<span><i>Exit</i></span><span><i>Exit</i></span>";
  } else if (/artists/.test(originalPath.path[0])) {
    originalPath.artist = true;
    btns.querySelectorAll(".featured, .artists, .studio").forEach((btn) => {
      btns.removeChild(btn);
    });

    document.querySelector(".menu").innerHTML =
      "Exit<span><i>Exit</i></span><span><i>Exit</i></span>";
  } else {
    btns
      .querySelectorAll(".work, .featured, .artists, .studio")
      .forEach((btn) => {
        btns.removeChild(btn);
      });
  }
} else {
  originalPath.shared = 0;
}

originalPath.single = originalPath.path[0] == "work" && !originalPath.path[1];

if (originalPath.path[1]) {
  let tiles = [];
  for (let i = 1; i < originalPath.path.length; i++) {
    originalPath.path[i] && tiles.push(originalPath.path[i]);
  }
  originalPath.tiles = tiles;

  let newWork = [];
  let countNewWork = 0;

  for (const [key, project] of Object.entries(window.AP.work)) {
    console.log(project.u);
    if (tiles.includes(project.u)) {
      newWork.push(project);
      // countNewWork++
    } else {
      let filtered = [];
      project.v.forEach(function (a, m) {
        if (tiles.includes(a.k)) {
          filtered.push(a);
        }
      });
      if (filtered.length) {
        project.v = filtered;
        newWork.push(project);
        // countNewWork++
      }
    }
  }

  originalPath.single = newWork.length == 1;

  if (/share|work/.test(originalPath.path[0]) && newWork.length > 1) {
    (document.querySelector("html") || document.documentElement).classList.add(
      "show-index"
    );
    document.querySelector("#btns .work").classList.add("nd");
    document.querySelector("#btns .work").classList.add("uvv");
  }

  console.log({ length: newWork.length });

  window.AP.work = newWork;
  window.AP.count = window.AP.work.length;
  window.AP.feat = false;
}

if (originalPath.single) {
  console.log("SINGLE SINGLE");
  (document.querySelector("html") || document.documentElement).classList.add(
    "single"
  );
}

if (/reels|artists/.test(originalPath.path[0])) {
  window.isReel = 1;
  let url = `https://cms.ancillarypost.com/${originalPath.path[0]}/${originalPath.path[1]}/`;
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      console.log("data", data);
      if (data.protected) {
        console.log("protected");
        import("../reels/password").then((module) => {
          module.default(url);
        });
      } else {
        console.log("public");
        window.AP.work = Object.values(data.work);
        window.AP.count = window.AP.work.length;
        originalPath.single = window.AP.count == 1;
        window.AP.feat = false;
        window.setup();

        if (data.showIndex) {
          (
            document.querySelector("html") || document.documentElement
          ).classList.add("show-index");
          document.querySelector("#btns .work").classList.add("nd");
          document.querySelector("#btns .work").classList.add("uvv");
        }

        if (data.artistName) {
          document.querySelector(
            "#filter"
          ).innerHTML = `<a class="nd uv uvv">${data.artistName}<span><i>${data.artistName}</i></span><span><i>${data.artistName}</i></span></a>`;
        }
      }
    })
    .catch((error) => {
      console.log("fetch error", error);
    });
  originalPath.reels = 1;
} else {
  originalPath.reels = 0;
}

export default originalPath;
