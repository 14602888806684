/**
 * RGB Shift Shader
 * Shifts red and blue channels from center in opposite directions
 * Ported from http://kriss.cx/tom/2009/05/rgb-shift/
 * by Tom Butterworth / http://kriss.cx/tom/
 *
 * amount: shift distance (1 is width of input)
 * angle: shift angle in radians
 */

import {
  MathUtils,
  DataTexture,
  RGBFormat,
  FloatType,
  LuminanceFormat,
  UnsignedByteType
} from 'three'

function generateHeightmap(dt_size) {
  const data_arr = new Float32Array(dt_size * dt_size * 3)
  const length = dt_size * dt_size

  for (let i = 0; i < length; i++) {
    const val = MathUtils.randFloat(0, 1)
    data_arr[i * 3 + 0] = val
    data_arr[i * 3 + 1] = val
    data_arr[i * 3 + 2] = val
  }

  return new DataTexture(data_arr, dt_size, dt_size, RGBFormat, FloatType)
}

function unit8Tex(side) {
  /*Data texture*/
  //   var side = 32 // power of two textures are better cause powers of two are required by some algorithms. Like ones that decide what color will pixel have if amount of pixels is less than amount of textels (see three.js console error when given non-power-of-two texture)

  var amount = Math.pow(side, 2) // you need 4 values for every pixel in side*side plane
  var data = new Uint8Array(amount)
  /* 
  You can also use any js typed array or ArrayBuffer itself
  Most popular is Uint8Array (it can contain itegers from 0 to 255)
*/
  for (var i = 0; i < amount; i = i + 2) {
    let val = Math.round(Math.random()) * 255
    data[i] = val
    data[i + 1] = val
    //   for (var i = 0; i < amount; i++) {
    //     data[i] = Math.round(Math.random()) * 255 // generates random r,g,b,a values from 0 to 1
    /*  
    When using Uint8Array multiply the random value by 255 to get same results
    'cause in that case you use integers from 0 to 255 to represent colors
    which is limiting but is more widely supported (see comment below)
  */
  }
  //   console.log(renderer.context.getExtension('OES_texture_float'))
  //   console.log(renderer.context.getExtension('OES_texture_float_linear'))
  /* 
   If you got nothing, check console to see if you have this extension
   If not: use Uint8Array instead of Float32Array and THREE.UnsignedByteType istead of 
   THREE.FloatType in texture constructor
*/
  return new DataTexture(data, side, side, LuminanceFormat, UnsignedByteType)
}

const DigitalGlitch = {
  uniforms: {
    tDiffuse: { value: null }, //diffuse texture
    tDisp: { value: 1 ? unit8Tex(8) : generateHeightmap(64) }, //displacement texture for digital glitch squares
    byp: { value: 0 }, //apply the glitch ?
    amount: { value: 0 },
    angle: { value: 0 },
    seed: { value: 0.75 },
    seed_x: { value: 0 }, //-1,1
    seed_y: { value: 0 }, //-1,1
    distortion_x: { value: 0 },
    distortion_y: { value: 0 },
    col_s: { value: 0.05 }
  },

  vertexShader: /* glsl */ `
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

  fragmentShader: /* glsl */ `
		uniform int byp; //should we apply the glitch ?
		uniform sampler2D tDiffuse;
		uniform sampler2D tDisp;
		uniform float amount;
		uniform float angle;
		uniform float seed;
		uniform float seed_x;
		uniform float seed_y;
		uniform float distortion_x;
		uniform float distortion_y;
		uniform float col_s;
		varying vec2 vUv;
		float rand(vec2 co){
			return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
		}
		void main() {
			if(byp<1) {
				vec2 p = vUv;
				float xs = floor(gl_FragCoord.x / 0.5);
				float ys = floor(gl_FragCoord.y / 0.5);
				//based on staffantans glitch shader for unity https://github.com/staffantan/unityglitch
				vec4 normal = texture2D (tDisp, p*seed*seed);
				if(p.y<distortion_x+col_s && p.y>distortion_x-col_s*seed) {
					if(seed_x>0.){
						p.y = 1. - (p.y + distortion_y);
					}
					else {
						p.y = distortion_y;
					}
				}
				if(p.x<distortion_y+col_s && p.x>distortion_y-col_s*seed) {
					if(seed_y>0.){
						p.x=distortion_x;
					}
					else {
						p.x = 1. - (p.x + distortion_x);
					}
				}
				p.x+=normal.x*seed_x*(seed/5.);
				p.y+=normal.y*seed_y*(seed/5.);
				//base from RGB shift shader
				// vec2 offset = amount * vec2( cos(angle), sin(angle));
				// vec4 cr = texture2D(tDiffuse, p);
				// vec4 cga = texture2D(tDiffuse, p);
				// vec4 cb = texture2D(tDiffuse, p);
				// gl_FragColor = vec4(cr.r, cga.g, cb.b, cga.a);
								vec4 cl = texture2D(tDiffuse, p);
				gl_FragColor = vec4(cl.r, cl.g, cl.b, cl.a);
				//add noise
				// vec4 snow = 200.*amount*vec4(rand(vec2(xs * seed,ys * seed*50.))*0.2);
				// gl_FragColor = gl_FragColor+ snow;
			}
			else {
				gl_FragColor=texture2D (tDiffuse, vUv);
			}
		}`
}

export { DigitalGlitch }
