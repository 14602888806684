import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera'
import unitsManager from '../managers/unitsManager'

const camera = new PerspectiveCamera(
  unitsManager.camFov,
  unitsManager.camAspect,
  0.1,
  1500
)

camera.position.z = unitsManager.camZ

export default camera
