export function setupCareers() {
    const careersBox = document.querySelector('#careers');
    const jobsList = document.querySelector('#jobs');
    console.log('careers box')
    console.log(window.AP.careers)
    window.AP.careers.openings.forEach(job => {
        const a = document.createElement('div');
        a.classList = 'job';
        a.innerHTML = `<div class="header hv"><span class="title">${job.title}</span>
        <span class="type">${job.type}</span>
        <span class="location">${job.location}</span></div>
        <div class="body"><div class="inner">${job.info}${job.apply ? `<br><div class="apply"><a class="hv" href="${job.apply}" target="_blank" rel="noopener">${job.applyText || 'Apply Now'}</a></div>` : ''}</div></div>`;
        jobsList.appendChild(a)
    });



    document.querySelector('#careers-info').innerHTML = window.AP.careers.info;

    function heights() {
        console.log('heights')
        jobsList.querySelectorAll('.job').forEach((job) => {
            console.log('job height', job.querySelector('.inner').offsetHeight)
            job.style = `--h:${job.querySelector('.inner').offsetHeight}px`;
        })
    }

    heights();

    jobsList.querySelectorAll('.job').forEach((job) => {
        job.querySelector('.header').addEventListener('click', () => {
            if (job.classList.contains('active')) {
                job.classList.remove('active')
            } else {
                jobsList.querySelectorAll('.job').forEach((otherJob) => {
                    otherJob.classList.remove('active')
                })
                heights();
                job.classList.add('active')
            }

        })
    })


}