import { windowManager } from '../globals/globals'
import resizeManager from './resizeManager'

// windowManager.resize && windowManager.resize();

const resizeUnitsManager = function () {
  var camAspect = windowManager.iw / windowManager.ih
  var virtFov = (50 * Math.PI) / 180
  var virtHeight = 2 * Math.tan(virtFov / 2) * Math.abs(900)
  var virtWidth = virtHeight * camAspect

  var siteMargin = 40
  var scaleMargin = 116

  var maxVirtHeight = virtHeight - siteMargin * (virtHeight / windowManager.ih)

  var maxVirtWidth = virtWidth - siteMargin * (virtWidth / windowManager.iw)

  var scaleVirtHeight =
    virtHeight - scaleMargin * (virtHeight / windowManager.ih)

  unitsManager.camAspect = windowManager.iw / windowManager.ih
  unitsManager.virtFov = (50 * Math.PI) / 180
  unitsManager.virtHeight = virtHeight
  unitsManager.virtWidth = virtWidth
  unitsManager.maxVirtHeight = maxVirtHeight
  unitsManager.maxVirtWidth = maxVirtWidth
  unitsManager.scaleVirtHeight = scaleVirtHeight

  resizeManager.resize()
}

// const vFOV = (unitsManager.camFov * Math.PI) / 180
// var vHeight = 2 * Math.tan(vFOV / 2) * abs(unitsManager.camZ)
// var vWidth = vHeight * unitsManager.camAspect

// var vw = vWidth / 100
// var vh = vHeight / 100

// var videoWidth = 65 * vh * 1.77
// var videoHeight = 65 * vh

// if (videoWidth > vWidth * 1.33) {
//   videoWidth = vWidth * 1.33
//   videoHeight = (vWidth / 1.77) * 1.33
// }

function faveOffset(stack, tile, ox = 0, oy = 0) {
  let s = stacksDOM[stack.ix].style
  let w = parseFloat(s.width.replace('px', ''))
  let h = parseFloat(s.height.replace('px', ''))
  let r = tile.ratio / 1.778
  tile.fx = tile.ratio < 1.778 ? (w - w * r) / 2 : 0
  r = 1.778 / tile.ratio
  tile.fy = tile.ratio > 1.778 ? (h - h * r) / 2 : 0
  // console.log('faveOffset', tile.ratio)
  s.setProperty('--fx', -(tile.fx - ox) + 'px')
  s.setProperty('--fy', tile.fy + 'px')
}

function titleOffset(stack, ratio) {
  var ratios = []
  stack.children.forEach(function (tile) {
    ratios.push(tile.ratio)
  })

  ratio = 1.778
  if (ratios.length < 4) {
    ratio = Math.max(1.778, Math.min.apply(Math, ratios))
  } else {
    var nRatios = []
    for (let step = 0; step < 3; step++) {
      var pos = stack.curTop + step
      if (pos > ratios.length) {
        pos = pos - ratios.length
      }
      nRatios.push(ratios[pos])
    }
    ratios = nRatios
    ratio = Math.max(1.778, Math.min.apply(Math, ratios))
  }

  if (windowManager.iw < 900 && ratio > 1.778) {
    ratio = 1.778
  }
  var offset = 1.778 - Math.max(1.778, ratio)
  if (Math.abs(offset) > 0.05) {
    offset = (1000 - (1.778 / ratio) * 1000) / 2
  } else {
    offset = 0
  }
  return offset
}

const unitsManager = {
  camFov: 50,
  camZ: 900,
  resize: resizeUnitsManager,
  titleOffset: titleOffset,
  faveOffset: faveOffset
}

// unitsManager.resize()

export default unitsManager
