const state = {
  currentProject: 0,
  scaleAnimating: 0,
  viewType: 'full'
}

// document.querySelector('.app-state').innerHTML = JSON.stringify(state, null, 4)

// unitsManager.resize()

export default state
