import { params } from '../setup/params'
import supportManager from '../managers/supportManager'
import unitsManager from '../managers/unitsManager'

var d = document,
  w = window,
  main = d.querySelector('main'),
  EP = Element.prototype

const select = function (selector) {
  return d.querySelector(selector)
}

const selectAll = function (selector) {
  return d.querySelectorAll(selector)
}

const mathManager = {
  abs: Math.abs,
  min: Math.min,
  max: Math.max,
  round: Math.round,
  ceil: Math.ceil,
  floor: Math.floor,
  sin: Math.sin,
  cos: Math.cos,
  tan: Math.tan
}

const appManager = {
  d: d,
  h: select('html') || d.documentElement,
  b: d.body
}

const windowManagerResize = function () {
  windowManager.iw = w.innerWidth
  windowManager.iw2 = w.innerWidth / 2
  windowManager.ih = w.innerHeight
  windowManager.ih2 = w.innerHeight / 2
  // windowManager.sh =
  //   w.innerHeight *
  //   (w.innerHeight > w.innerWidth ? w.innerWidth / w.innerHeight : 1);
  windowManager.sh = Math.round(
    w.innerHeight *
    (mathManager.min(w.innerWidth / w.innerHeight, 1) *
      (params.scrollHeight / 100))) + 0.1
  windowManager.sp =
    w.innerHeight *
    (mathManager.min(w.innerWidth / w.innerHeight, 1) *
      (params.scrollHeight / 100))
  windowManager.so = (w.innerHeight - windowManager.sh) / 2
  unitsManager.resize()
  supportManager.touch &&
    document.documentElement.style.setProperty(
      '--ih2',
      windowManager.ih2 + 'px'
    )
}

const windowManager = {
  w: w,
  iw: 0,
  iw2: 0,
  ih: 0,
  ih2: 0,
  sh: 0,
  so: 0,
  pr: window.devicePixelRatio,
  resize: windowManagerResize
}

export {
  appManager,
  windowManager,
  mathManager,
  supportManager,
  select,
  selectAll
}
