import { windowManager } from "../globals/globals";
import supportManager from "../managers/supportManager";
import { WebGLRenderer } from "three/src/renderers/WebGLRenderer";
import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer.js";
import originalPath from "../utils/originalPath";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";

let webglRenderer;

function createContext(performanceCaveat) {
  webglRenderer = new WebGLRenderer({
    premultipliedAlpha: false,
    alpha: false,
    antialias: supportManager.antialias,
    stencil: false,
    depth: false,
    logarithmicDepthBuffer: supportManager.depthBuffer,
    powerPreference: "high-performance",
    failIfMajorPerformanceCaveat: !!performanceCaveat,
    // autoClear: true,
    // precision: 'lowp'
  });
  supportManager.gpuAccelerated = !!performanceCaveat;
}

try {
  createContext(1);
} catch (error) {
  createContext(0);
}

const Renderer = [webglRenderer, new CSS3DRenderer()];

Renderer.push(new EffectComposer(webglRenderer));

Renderer[0].setClearColor(originalPath.light ? 0xfffaf5 : 0x0e0e0e, 0);
Renderer[0].setPixelRatio(supportManager.pixelRatio);

Renderer[1].setSize(windowManager.iw, windowManager.ih);
document.body.appendChild(Renderer[1].domElement);
Renderer[1].domElement.id = "scroller";

export default Renderer;
