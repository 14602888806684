import "./styles/inline.scss";
import "./styles/style.scss";
import {
  appManager,
  windowManager,
  mathManager,
  select,
  selectAll,
} from "./components/globals/globals";
("three");

import supportManager from "./components/managers/supportManager";

import {
  PlaneBufferGeometry,
  Group,
  Texture,
  VideoTexture,
  TextureLoader,
  ShaderMaterial,
  Mesh,
  MeshBasicMaterial,
  LinearFilter,
  RGBFormat,
  RGBAFormat,
  CustomBlending,
  AddEquation,
  SrcAlphaFactor,
  OneMinusSrcAlphaFactor,
  Color,
} from "three/src/Three";

import { threeMath } from "three/src/math/MathUtils";

import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer.js";
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'

import { NodePass } from "three/examples/jsm/nodes/postprocessing/NodePass.js";
import * as Nodes from "three/examples/jsm/nodes/Nodes.js";

import { TweenMax, Linear, Power3 } from "gsap";
import unitsManager from "./components/managers/unitsManager";
import stackDom from "./components/setup/stackDom";
import cloudflareUrl from "./components/utils/cloudflareUrl";
import scaleVolManager from "./components/managers/scaleVolManager";
import Renderer from "./components/setup/renderer";
import originalPath from "./components/utils/originalPath";
import shareManager from "./components/managers/shareManager";
import scene from "./components/setup/scenes";
import cam from "./components/setup/camera";

import Stacks from "./components/live/stacks";

import state from "./components/setup/state";

import shaderManager from "./components/shader/shaderManager";
import resizeManager from "./components/managers/resizeManager";

import { slideOut, slideIn, moveTo } from "./components/utils/scrollToWork";

import {
  remove_duplicates,
  array_remove,
  closest,
} from "./components/utils/utils";

import { decode } from "blurhash";

import playPromise from "./components/utils/playPromise";

import { setupCareers } from "./components/setup/careers";

var d = document,
  html = d.querySelector("html") || d.documentElement,
  body = d.body,
  w = window,
  main = d.querySelector("main"),
  EP = Element.prototype,
  careers = window.location.pathname.includes("/careers");

w.faves = [];

// cookie disabled
if (d.cookie && false) {
  var result = document.cookie.match(new RegExp("w.faves=([^;]+)"));
  if (result) {
    w.faves = result[1].split(",");
  }
}

shareManager.w();

w.iOS = supportManager.iOS;

w.playNative = 0;

if (supportManager.iOS) {
  w.playNative = 1;
}

var dashSupport = 0,
  hlsSupport = 0;
if (
  dashjs.supportsMediaSource &&
  !supportManager.iOS &&
  supportManager.base != "safari"
) {
  dashSupport = 1;
}

// SETUP
import { params, gui } from "./components/setup/params";
// ACTION
import {
  stackManager,
  stackCredits,
  stackNext,
} from "./components/managers/stackManager";
import { isPowerOfTwo } from "three/src/math/MathUtils";
// import { renderList } from 'vue'

// import gsap from "gsap";

w.playAC = 0;
w.playTS = 0;

var abs = Math.abs;
var min = Math.min;
var max = Math.max;
var sin = Math.sin;
var cos = Math.cos;
var floor = Math.floor;
var round = Math.round;
var pi = Math.PI;

w.volumeIs = 0;
w.volumeBe = 0;
w.volumeOg = 0.5;
w.volumeSt = 0.5;

// SETUP // SETUP // SETUP

function setup() {
  var projectsCountOG = 0;
  var projectsCount = 0;
  var projects = w.AP.work;

  // console.log('projects', projects)

  function create(a) {
    return d.createElement(a);
  }

  function q(a, c = 0) {
    var b = d.querySelectorAll(a);
    return b.length > 1 ? b : c ? [d.querySelector(a)] : d.querySelector(a);
  }

  function pos(a) {
    return [a.position.x, a.position.y, a.position.z];
  }

  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual";
  }

  var filterList = q("#filter");
  // filterList.innerHTML = "";
  var filterArray = [];

  var possbileFilters = [
    // 'Visual',
    "Edit",
    "Color",
    "Graphics",
    // 'Audio',
    "Sound Design",
    "Sound Mix",
    "Sound",
    "Music",
  ];
  var existingFilters = [];
  var visual = [];
  var audio = [];
  var cred = {};
  // var byF = []
  for (const [key, project] of Object.entries(window.AP.work)) {
    // console.log(project.f)
    project.f.Visual &&
      project.f.Visual.forEach(function (filter) {
        if (possbileFilters.includes(filter)) {
          visual.push(filter);
          // existingFilters.push('Visual')
        }
      });
    project.f.Audio &&
      project.f.Audio.forEach(function (filter) {
        if (possbileFilters.includes(filter)) {
          audio.push(filter);
          // existingFilters.push('Audio')
        }
      });
    if (project.f.cred) {
      console.log(project.f.cred);
      project.f.cred.forEach(function (filter, i) {
        if (!possbileFilters.includes(filter)) {
          if (!cred[i]) {
            cred[i] = [filter];
          } else {
            cred[i].push(filter);
          }
        }
      });
    }
  }

  // console.log(
  //   remove_duplicates(visual),
  //   remove_duplicates(audio),
  //   remove_duplicates(byF)
  // )

  visual = remove_duplicates(visual);
  audio = remove_duplicates(audio);

  let filterHTML = "";
  let filterCount = 0;
  if (visual.length) {
    filterHTML += `<a href="/filter/" data-f="${visual.join(
      ","
    )}" class="uv nd ct visual" style="--t:'Visual';--n:${filterCount}">Visual<span><i>Visual</i></span><span><i>Visual</i></span></a>`;
    filterCount++;
  }
  possbileFilters.forEach(function (filter) {
    if (visual.includes(filter)) {
      let lower = filter.replace(" ", "-");
      lower = lower.toLowerCase();
      filterHTML += `<a href="/filter/${lower}" data-f="${filter}" class="uv nd fl ${lower}" style="--t:'${filter}';--n:${filterCount}">${filter}<span><i>${filter}</i></span><span><i>${filter}</i></span></a>`;
      filterCount++;
    }
  });
  if (audio.length) {
    filterHTML += `<a href="/filter/" data-f="${audio.join(
      ","
    )}" class="uv nd ct audio" style="--t:'Audio';--n:${filterCount}">Audio<span><i>Audio</i></span><span><i>Audio</i></span></a>`;
    filterCount++;
  }
  possbileFilters.forEach(function (filter) {
    if (audio.includes(filter)) {
      let lower = filter.replace(" ", "-");
      lower = lower.toLowerCase();
      filterHTML += `<a href="/filter/${lower}" data-f="${filter}" class="uv nd fl ${lower}" style="--t:'${filter}';--n:${filterCount}">${filter}<span><i>${filter}</i></span><span><i>${filter}</i></span></a>`;
      filterCount++;
    }
  });
  // cred.forEach(function (cred) {})

  // cred = remove_duplicates(cred)

  // console.log('CREDITS', cred)

  for (let [key, value] of Object.entries(cred)) {
    value = remove_duplicates(value);
    value.forEach(function (filter) {
      let lower = filter.replace(/ /g, "-");
      lower = lower.toLowerCase();
      filterHTML += `<a href="/filter/${lower}" data-f="${filter}" class="uv nd cr ${lower}" style="--t:'${filter}';--n:${filterCount}">${filter}<span><i>${filter}</i></span><span><i>${filter}</i></span></a>`;
    });
    filterCount++;
  }

  if (!originalPath.artist) {
    filterList.innerHTML = filterHTML;
  }

  const filterManager = {
    activeFilters: [],
    activeProjects: [],
    resolveTimeout: 0,
    was: 0,
  };

  var activeFilters = [];
  var filterResolveTimeout = 0;

  function updateCenterConst(off = 0) {
    w.centerConst = round(w.scrollConst) + off;
    w.workConst = w.centerConst - w.acStacks[1];
    notice2.innerHTML = w.centerConst;
  }

  function unfilter(restore = 0) {
    filterManager.restore = restore;
    !restore && (filterManager.activeProjects = []);
    filterList.classList.remove("ft");
    filterResolve(w.acStacks[1], ogStacks, 0);
    q("#filter a").forEach(function (a) {
      a.classList.remove("ac");
    });

    notice.innerHTML += "<p>unfilter</p>";
    notice.innerHTML += "<p>" + ogStacks.toString() + "</p>";
  }

  function doFilter() {
    console.log("acFilters", filterManager.activeFilters);
    q("#filter a").forEach(function (a) {
      if (filterManager.activeFilters.includes(a.dataset.f)) {
        a.classList.add("ac");
      } else {
        a.classList.remove("ac");
      }
    });

    notice.innerHTML += "<p>filterManager.activeFilters</p>";

    document.querySelectorAll("#filter a.ct").forEach(function (a) {
      var arr = a.dataset.f.split(",");
      var sz1 = arr.length;
      var sz2 = 0;

      arr.forEach(function (b) {
        if (filterManager.activeFilters.includes(b)) sz2++;
      });

      if (sz1 == sz2) {
        a.classList.add("ac");
      } else {
        a.classList.remove("ac");
      }
    });

    //     console.log("sorted filter", filters);

    filterManager.activeProjects = [];
    if (filterManager.activeFilters.length) {
      filterList.classList.add("ft");
      filterManager.activeFilters.forEach(function (filter) {
        // var filter = c;
        var pc = 0;
        for (const [k1, project] of Object.entries(projects)) {
          if (project.f) {
            for (const [k2, cat] of Object.entries(project.f)) {
              cat.forEach(function (b) {
                // console.log(b, filter);
                if (b == filter) {
                  filterManager.activeProjects.push(pc);
                  // console.log("push", pc);
                }
              });
            }
          }
          pc++;
        }
      });

      notice.innerHTML +=
        "<p>" + filterManager.activeProjects.toString() + "</p>";
      updateCenterConst();

      if (filterManager.activeProjects.length || toProj) {
        // filterManager.activeProjects;
        let pjs = remove_duplicates(filterManager.activeProjects);
        pjs.sort();
        notice.innerHTML += "<p>" + pjs.toString() + "</p>";
        if (pjs.includes(w.acStacks[0]) || pjs.includes(w.acStacks[1])) {
          if (pjs.includes(w.acStacks[0]) && pjs.includes(w.acStacks[1])) {
            // updateCenterConst();
            scroller.scrollTop = round(w.tween.scroll) * windowManager.sh;
            notice.innerHTML += "<p>match both</p>";
            filterResolve(w.acStacks[1], pjs, 500);
          } else {
            notice.innerHTML += "<p>match one</p>";
            if (pjs.includes(w.acStacks[0])) {
              notice.innerHTML += "<p>pos 0</p>";
              notice.innerHTML += "<p>scroll</p>";
              // updateCenterConst(-1);
              w.acStacks = [w.acStacks[0], w.acStacks[1]];
              w.refStacks = [...w.acStacks];
              // scrollAc = 1;
              scroller.scrollTop =
                (round(w.tween.scroll) +
                  (w.tween.scroll > round(w.tween.scroll) ? -1 : 1)) *
                windowManager.sh;
              // currentProject(closestP);
              filterResolve(w.acStacks[1], pjs, 500);
            } else {
              notice.innerHTML += "<p>pos 1</p>";
              notice.innerHTML += "<p>no scroll</p>";
              // updateCenterConst();
              scroller.scrollTop = round(w.tween.scroll) * windowManager.sh;
              filterResolve(w.acStacks[1], pjs, 500);
            }
          }
        } else {
          notice.innerHTML += "<p>no match</p>";
          var closestP = closest(pjs, currentProjectIndex())[0];
          updateCenterConst();
          // w.centerConst = round(w.tween.scroll);
          w.acStacks = [w.acStacks[0], w.acStacks[1], closestP];
          w.refStacks = [...w.acStacks];
          // scrollAc = 1;
          scroller.scrollTop =
            (round(w.tween.scroll) +
              (w.tween.scroll > round(w.tween.scroll) ? -1 : 1)) *
            windowManager.sh;
          currentProject(closestP);
          filterResolve(closestP, pjs, 500);
        }
      } else {
        unfilter();
      }
    } else {
      unfilter();
    }
  }

  function filterResolve(visibleStack, newStacks, delay, updateConst = 1) {
    filterResolveTimeout && clearTimeout(filterResolveTimeout);
    filterResolveTimeout = setTimeout(function () {
      // scrollAc = 0;
      body.dataset.scroll = 0;
      var shifts = 0;
      let to = newStacks.includes(w.acStacks[1])
        ? w.acStacks[1]
        : w.acStacks[0];
      while (to != newStacks[1] && shifts < Stacks.length + 5) {
        newStacks.push(newStacks.shift());
        shifts++;
      }

      notice.innerHTML += "<p>" + w.acStacks[0] + "," + w.acStacks[1] + "</p>";

      if (newStacks.length == 1) {
        originalPath.single = 1;
      } else {
        originalPath.single = 0;
        updateConst && updateCenterConst();
        body.dataset.scroll = 1;
        w.acStacks = newStacks;
        w.refStacks = [...w.acStacks];
      }

      notice.innerHTML += "<p>" + w.acStacks.toString() + "</p>";
      filterResolveTimeout && clearTimeout(filterResolveTimeout);
      filterResolveTimeout = 0;

      console.log("ogStacks", ogStacks);
      ogStacks.forEach(function (a) {
        if (!w.acStacks.includes(a)) {
          if (Stacks[a][0]) Stacks[a][0].visible = false;
          if (Stacks[a][1]) Stacks[a][1].visible = false;
        }
      });

      // if (!w.acStacks.includes(ogStacks.length)) {

      if (Stacks[w.acStacks[0]][0]) Stacks[w.acStacks[0]][0].visible = true;
      if (Stacks[w.acStacks[0]][1]) Stacks[w.acStacks[0]][1].visible = true;
      if (Stacks[w.acStacks[1]][0]) Stacks[w.acStacks[1]][0].visible = true;
      if (Stacks[w.acStacks[1]][1]) Stacks[w.acStacks[1]][1].visible = true;
    }, delay);
  }

  q("#filter a[href]:not(.ext)")?.length &&
    q("#filter a[href]:not(.ext)").forEach(function (a) {
      console.log("filter link");
      a.addEventListener("click", function (e) {
        e.preventDefault();

        if (!filterResolveTimeout) {
          var t = e.target;
          while (t.nodeName != "A") {
            t = t.offsetParent;
          }

          notice.innerHTML = "";

          if (!t.classList.contains("by") && t.classList.contains("ac")) {
            t.dataset.f.split(",").forEach(function (b) {
              filterManager.activeFilters = array_remove(
                filterManager.activeFilters,
                b
              );
            });
          } else {
            t.dataset.f.split(",").forEach(function (b) {
              filterManager.activeFilters.push(b);
            });
          }

          filterManager.activeFilters = remove_duplicates(
            filterManager.activeFilters
          );

          console.log("acFilters", filterManager.activeFilters);

          doFilter();
        }
      });
    });

  var filterA = [];

  for (const [k1, project] of Object.entries(projects)) {
    projectsCountOG++;
    if (project.f) {
      var a = [];
      for (const [k2, cat] of Object.entries(project.f)) {
        a.push(cat);
      }
      a = [].concat.apply([], a);
      filterA.push(a);
    } else {
      filterA.push([]);
    }
  }

  projectsCount = projectsCountOG;

  resize();

  const vFOV = (unitsManager.camFov * Math.PI) / 180;
  var vHeight = 2 * Math.tan(vFOV / 2) * abs(unitsManager.camZ);
  var vWidth = vHeight * unitsManager.camAspect;

  var vw = vWidth / 100;
  var vh = vHeight / 100;

  var videoWidth = 65 * vh * 1.77;
  var videoHeight = 65 * vh;

  if (videoWidth > vWidth * 1.33) {
    videoWidth = vWidth * 1.33;
    videoHeight = (vWidth / 1.77) * 1.33;
  }

  function isMobile() {
    return windowManager.iw < 800;
  }

  var maxHeight = 65;

  maxHeight = min(maxHeight, (w.innerWidth / 1.333 / w.innerHeight) * 100);

  function vidSize(ratio) {
    var vidWidth = maxHeight * vh * ratio;
    var vidHeight = maxHeight * vh;

    if (ratio > 1 && ratio > 1.778 && w.innerWidth > 850) {
      vidWidth = maxHeight * vh * 1.778;
      vidHeight = vidWidth / ratio;
    }
    if (ratio <= 1) {
      vidHeight = maxHeight * vh;
      vidWidth = maxHeight * vh * ratio;
    }
    return [vidWidth, vidHeight];
  }

  w.renderer = [Renderer, scene, cam];

  var scrDiv = create("div");

  var i;
  var html = "";
  // if (!originalPath.single) {
  for (i = 0; i < 1000; i++) {
    html += '<div style="height:' + windowManager.sh + 'px">' + i + "</div>";
    scrDiv.classList = "snap";
  }
  // }
  scrDiv.id = "scrollHolder";
  scrDiv.innerHTML = html;

  Renderer[1].domElement.append(scrDiv);

  var stacksDOM = [];

  var stackY = 0;
  var videos = [];
  var videoGap = vh * 50;

  var size = vidSize(1.777);
  var geometry16x9 = new PlaneBufferGeometry(size[0], size[1]);
  var geometry;

  var workTable = q("#work .tbl");

  var z = 0;

  w.centerConst = 499;
  w.workConst = 499;

  let zPos = 2;

  const blendCol = new Color(originalPath.light ? 0xfffaf5 : 0x0e0e0e);

  function getChromaKeyShaderMaterial(texture) {
    const vertexShader = `
    varying vec2 vUv;
    void main( void ) {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
    `;
    const fragmentShader = `
    varying vec2 vUv;
    uniform sampler2D map;
    uniform float opacity; 
    uniform vec3 colour;
    void main() {
      gl_FragColor = mix(texture2D(map, vUv),vec4(colour.rgb,1),opacity);
    }
    `;

    return new ShaderMaterial({
      transparent: false,
      depthTest: false,
      depthWrite: false,
      uniforms: {
        map: {
          value: texture,
        },
        colour: {
          value: blendCol.toArray(),
        },
        opacity: {
          value: max(0.0001, min(1, 1 - opa)),
        },
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
  }

  window.AP.feat && window.AP.work.push(window.AP.feat);

  for (const [key, project] of Object.entries(window.AP.work)) {
    // console.log(originalPath, key, project)

    const stack = new Group();
    stack.ix = z;
    stack.url = project.u;
    stack.title = project.t ? project.t : 0;
    stack.visible = false;
    const stackDOM = new Group();
    stackDOM.visible = false;
    var start = Math.floor(Math.random() * 2);
    var left = 0 - 0.3 - Math.random();
    var right = 0.3 + Math.random();
    var top = 0.1 + Math.random();
    var rgb = 0;
    var opa = 1;
    var video = 0;
    var image = 0;
    let i = -1;
    var newV = [];

    project.v.forEach(function (a, m) {
      if (originalPath.tiles && originalPath.tiles.includes(a.k)) {
        newV.push(a);
      }
      i++;
      if (a.ratio) {
        size = vidSize(a.ratio);
        geometry = new PlaneBufferGeometry(size[0], size[1]);
      } else {
        geometry = geometry16x9;
      }
      video = 0;
      var vidTex = 0;
      var imgTex = 0;
      opa = max(0, 1 - 0.3 * i);
      rgb = max(0, round(255 - 63.75 * i));
      // rgb = rgb>0?rgb:0;
      if (a.video) {
        video = create("video");
        ["muted", "playsinline"].forEach(function (a) {
          video.setAttribute(a, 1);
        });

        video.loop = true;
        video.muted = true;
        video.crossOrigin = "anonymous";

        if (dashSupport && a.video.id) {
          // dash Player
          // console.log('DASH')
          var dashPlayer = dashjs.MediaPlayer().create();
          dashPlayer.initialize(video, cloudflareUrl(a.video.id, "mpd"), false);
          video.dashPlayer = dashPlayer;
        } else if (!dashSupport && a.video.id) {
          // hls Player
          // console.log('HLS')
          var source = create("source");
          source.src = cloudflareUrl(a.video.id, "hls");
          source.type = "application/vnd.apple.mpegurl";
          video.appendChild(source);
        } else if (a.video.mp4) {
          // hls Player
          var source = create("source");
          source.src = a.video.mp4;
          source.type = "video/mp4";
          video.appendChild(source);
        }

        videos.push(video);

        video.update = 1;

        video.addEventListener("timeupdate", timeupdate);
        function timeupdate(event) {
          if (
            event.target.currentTime > 0.1 &&
            !event.target.plane.vidVisible
          ) {
            // console.log(event.target.currentTime)
            let plane = event.target.plane;
            let vidTex = event.target.vidTex;
            plane.vidVisible = 1;
            plane.material.uniforms.map.value &&
              plane.material.uniforms.map.value.dispose();
            plane.material.uniforms.map.value = vidTex;
            event.target.removeEventListener("timeupdate", timeupdate);
          }
        }

        // video.addEventListener('play', (event) => {
        //   console.log('play event')
        // })

        video.addEventListener("pause", (event) => {
          // console.log('pause event')
          var video = event.target;
          var tile = video.plane;
          // console.log("pause " + project.u, tile.playStatus);
          if (w.playNative && windowManager.iw < 900) {
            // combat iOS pause on exit fullscreen
            if (tile.playStatus) {
              console.log("iOS cancel pause");
              playPromise(video.play(), video.plane, "pause listener", video);
            }
          }
          tile.playStatus = 0;
        });

        if (w.playNative) {
          video.addEventListener("fullscreenchange", (event) => {
            console.log("fullscreenchange");
          });
          video.addEventListener("webkitendfullscreen", (event) => {
            console.log("webkitendfullscreen");
            document.body.removeChild(event.target);
            scaleVolManager.endFullscreen(event.target);
          });
        }

        vidTex = new VideoTexture(video);
        vidTex.anisotropy = 0.1;
        vidTex.minFilter = LinearFilter;
        vidTex.magFilter = LinearFilter;
        vidTex.format = RGBAFormat;
        vidTex.generateMipmaps = true;
        video.vidTex = vidTex;
      } else {
        image = 1;
      }

      var material;

      // material = new MeshBasicMaterial({
      //   // color:
      //   //   "rgb(" +
      //   //   (z ? rgb : 0) +
      //   //   "," +
      //   //   (z ? rgb : 0) +
      //   //   "," +
      //   //   (z ? rgb : 0) +
      //   //   ")",
      //   // map: vidTex ? vidTex : imgTex,
      //   // map: imgTex,

      //   blending: CustomBlending,
      //   blendEquation: AddEquation, //default
      //   blendSrc: SrcAlphaFactor, //default
      //   blendDst: OneMinusSrcAlphaFactor,

      //   // transparent: true,

      //   opacity: !z ? 0 : opa
      //   // depthWrite: false
      //   // depthTest: true,
      // })

      // if (video) {
      var mychromavideotexturematerial = getChromaKeyShaderMaterial(imgTex);
      material = mychromavideotexturematerial;
      // material.needsUpdate = true
      // }

      var plane = new Mesh(geometry, material);
      plane.vidVisible = 0;
      plane.imgLoaded = 0;
      if (!z) plane.scale.set(0.5, 0.5, 1);

      let validBlur = 0;

      if (a.blur) {
        try {
          a.blur = atob(a.blur);
        } catch (error) {}
        if (a.blur.length == 28) {
          validBlur = 1;
        } else {
          console.log(
            "invalid blur",
            a.blur.length,
            project.t + " – " + a.title,
            a.blur
          );
        }
      }

      if (a.blur && validBlur) {
        // setTimeout(function () {
        if (!plane.imgLoaded && !plane.vidVisible) {
          const pixels = decode(a.blur, 32, 32);
          const imgData = new ImageData(pixels, 32, 32);
          imgTex = new Texture(imgData);
          imgTex.needsUpdate = true;
          Renderer[0].initTexture(imgTex);
          plane.material.uniforms.map.value = imgTex;
          plane.material.needsUpdate = true;
          a.blur = false;
        }
        // }, 450);
      }

      if (!plane.vidVisible) {
        new TextureLoader().load(
          a.video ? cloudflareUrl(a.video.id, 0, a.video.pos) : a.img,
          function (loadImgTex) {
            if (!plane.vidVisible) {
              plane.imgLoaded = 1;
              Renderer[0].initTexture(loadImgTex);
              plane.material.uniforms.map.value &&
                plane.material.uniforms.map.value.dispose();
              plane.material.uniforms.map.value = loadImgTex;
              plane.material.needsUpdate = true;
            }
          }
        );
      }

      // plane.ratio = unitsManager.titleOffset(a.ratio);
      plane.ratio = a.ratio;

      if (video) {
        plane.volume = 0;
        plane.video = video;
        video.plane = plane;
        video.stack = stack;
      }
      if (image) {
        // plane.playStatus = 0;
        plane.image = 1;
      }
      plane.imgTex = imgTex;
      a.isMoving = 0;
      ["ogCoords", "isCoords", "beCoords"].forEach(function (b, j) {
        plane[b] = { x: 0, y: 0, z: 0 };
      });
      plane.colIs = { o: rgb };
      plane.colWill = { o: rgb };

      plane.msCoords = { x: 0, y: 0 };

      plane.ogX = { x: 0 };
      plane.ogY = { y: 0 };
      plane.ogZ = { z: 0 };

      plane.isX = { x: 0 };
      plane.isY = { y: 0 };
      plane.isZ = { z: 0 };

      plane.beX = { x: 0 };
      plane.beY = { y: 0 };
      plane.beZ = { z: 0 };

      plane.ogO = { o: z ? 1 : 0 };
      plane.isO = { o: z ? 1 : 0 };
      plane.beO = { o: opa };

      plane.slideO = { o: 0 };
      plane.slideX = { x: 0 };
      plane.slideY = { y: 0 };

      if (i) {
        plane.position.set(
          2 * vh * (start ? left : right),
          2 * vh * top,
          -zPos * i
        );

        plane.ogX = { x: 2 * vh * (start ? left : right) };
        plane.ogY = { y: 2 * vh * top };
        plane.ogZ = { z: -zPos * i };

        plane.isX = { x: 2 * vh * (start ? left : right) };
        plane.isY = { y: 2 * vh * top };
        plane.isZ = { z: -zPos * i };

        plane.beX = { x: 2 * vh * (start ? left : right) };
        plane.beY = { y: 2 * vh * top };
        plane.beZ = { z: -zPos * i };
        [("ogCoords", "isCoords", "beCoords")].forEach(function (b, j) {
          plane[b] = {
            x: 2 * vh * (start ? left : right),
            y: 2 * vh * top,
            z: -zPos * i,
          };
        });

        top = top + 0.1 + Math.random();
        if (start) {
          start = 0;
          left = 0 - left - 0.3 - Math.random();
        } else {
          start = 1;
          right = right + 0.3 + Math.random();
        }
      }
      plane.renderOrder = 99 - i;
      stack.add(plane);
      plane.k = a.k;
      plane.stack = stack;
      plane.title = a.title ? a.title : 0;
    });
    if (newV.length) {
      project.v = newV;
    }

    if (stack.children) {
      stack.position.set(0, -(videoHeight + videoGap) * stackY, 0);
      stack.curTop = 0;
      stack.stackZis = { z: -zPos };
      stack.stackZwill = { z: -zPos };
      stack.stackZcnt = 0;

      scene[0].add(stack);

      let planeDOM = document.createElement("div");
      planeDOM.innerHTML = stackDom(project, z);

      let rf = randomiseFont();

      if (project.v.length > 1) {
        var trHtml = "";
        project.v.forEach(function (a, i) {
          trHtml +=
            `<p id="${project.u}-+-${i}" class="hv" data-p="${z}" data-s="${i}">` +
            `<a class="fave${
              w.faves && w.faves.includes(a.k) ? " ac" : ""
            }" data-p="${project.k}" data-f="${a.k}" data-k="${a.k}"></a>` +
            `<span>${a.title ? rf + a.title + rf : i + 1}</span></p>`;
        });
      }

      var tr = create("div");
      tr.classList = "tr";
      tr.dataset.p = z;
      tr.id = project.u;
      tr.classList.add("mn");
      tr.innerHTML =
        `<div>${project.y ? project.y : ""}</div>` +
        `<div>${
          project.v.length > 1 ||
          (project.v[0] && project.v[0].title != project.t)
            ? project.t
            : ""
        }</div>` +
        "<div>" +
        (project.v.length > 1
          ? trHtml
          : `<p class="hv"><a class="fave${
              w.faves && w.faves.includes(project.v[0].k) ? " ac" : ""
            }" data-f="${project.v[0].k}" data-p="${project.k}" data-k="${
              project.v[0].k
            }"></a><span>${
              project.v[0] ? rf + project.v[0].title + rf : ""
            }</span></p>`) +
        "</div>" +
        `<div>
      ${
        project.f
          ? (project.f.Visual ? project.f.Visual.join(", ") : "") +
            (project.f.Visual &&
            project.f.Visual.length &&
            project.f.Audio &&
            project.f.Audio.length
              ? ", "
              : "") +
            (project.f.Audio ? project.f.Audio.join(", ") : "") +
            (((project.f.Audio && project.f.Audio.length) ||
              (project.f.Visual && project.f.Visual.length)) &&
            project.f.cred1
              ? ", "
              : " ") +
            (project.f.cred ? project.f.cred.join(" ") : "")
          : ""
      }</div>`;
      workTable.appendChild(tr);

      z++;

      var titleRatio = unitsManager.titleOffset(stack);

      planeDOM.className =
        "proj" +
        (project.y == 1 ? " feat fuv ac" : "") +
        (project.v.length == 1 ? " no-drag" : "") +
        (project.v[0].video ? "" : " vw");
      planeDOM.querySelector(".btm").style = "--t:" + titleRatio + "%";
      planeDOM.style.width = videoWidth + "px";
      planeDOM.style.height = videoHeight + "px";
      let cssElement = new CSS3DObject(planeDOM);
      cssElement.element.ix = stackY + 1;
      cssElement.element.mv = project.v.length > 1;
      stacksDOM.push(cssElement.element);
      cssElement.position.set(0, -(videoHeight + videoGap) * stackY, 0);
      scene[1].add(cssElement);

      Stacks.push([cssElement, stack]);

      // console.log("pushed", Stacks);

      stackY++;
    } else {
      console.log("not pushed no children");
    }
    // }
  }

  //console.log('Stacks', Stacks)

  if (supportManager.touch) {
    console.log("need to load touch scroller");
    import("./components/action/touchWorkSelect").then((module) => {
      module.default();
    });
  }

  window.stacksDOM = stacksDOM;
  window.allStacks = Stacks;
  // stackCredits(0, Stacks)

  setTimeout(function () {
    stackCredits(0, Stacks);
  }, 20);

  resize();

  setTimeout(function () {
    scene[0].children.forEach(function (obj) {
      var visib = obj.visible;
      obj.frustumCulled = false;
      obj.visible = true;
      obj.children.forEach(function (obj2) {
        var visib2 = obj2.visible;
        obj2.frustumCulled = false;
        obj2.visible = true;
        setTimeout(function () {
          obj2.frustumCulled = true;
          obj2.visible = visib2;
        }, 100);
      });

      setTimeout(function () {
        obj.frustumCulled = true;
        obj.visible = visib;
      }, 100);
    });
  }, 10);

  var allVisibleStacks = [];
  var allMovingStacks = [];
  var allMovingPlanes = [];

  var ogStacks = [];
  var i;
  for (i = 0; i < window.AP.count - 0; i++) {
    ogStacks.push(i);
  }
  ogStacks.unshift(ogStacks.pop());
  w.acStacks = ogStacks;
  w.ogStacks = ogStacks;

  var mouseX = 0;
  var mouseY = 0;

  w.speed1 = 0;

  var notice = q("#notice");
  var notice2 = q("#notice2");

  w.shaderOpacity = 0;
  w.shaderStrength = 0;
  w.shaderCancel = 0;
  w.shaderActive = 0;

  w.autoScroll = 0;

  var allFilter = [];

  document.querySelectorAll("#filter a").forEach(function (f) {
    // console.log("f", f.getAttribute("style").match(/(--t:')(.*)(?=';?)/)[2]);
    allFilter.push(f.getAttribute("style").match(/(--t:')(.*)(?=';?)/)[2]);
  });

  function currentProjectIndex() {
    return abs(w.aStacks[0][1]) < abs(w.aStacks[1][1])
      ? w.aStacks[0][0]
      : w.aStacks[1][0];
  }

  function currentProject(index) {
    var curFilterPos = 0;
    // console.log('current project',filterA[index]);
    allFilter.forEach(function (a, i) {
      // var filter = 0,
      let by = 0;
      var c = q("." + a.toLowerCase().replace(/ /g, "-"));
      if (c) {
        // console.log('c', c)
        c.style.transform =
          "translate3d(0," + (isMobile() ? 25 : 30) * curFilterPos + "px,0)";
        c.classList.remove("uvv");
      }

      if (filterA[index]) {
        filterA[index].forEach(function (b) {
          if (a == b) {
            if (c) c.classList.add("uvv");
            curFilterPos++;
            // filter = b;
          }
          if (a == b[0]) {
            // filter = b[0];
            by = b[1];
          }
        });
      }
    });

    stackCredits(originalPath.single ? 0 : index);
  }

  w.currentProject = currentProject;

  // var logoLetters = document.querySelectorAll('#logo i')

  // var touchLettersA = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  // var touchLettersT = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  // var touchLettersO = {
  //   l0: 1,
  //   l1: 1,
  //   l2: 1,
  //   l3: 1,
  //   l4: 1,
  //   l5: 1,
  //   l6: 1,
  //   l7: 1,
  //   l8: 1,
  //   l9: 1,
  //   l10: 1,
  //   l11: 1,
  //   l12: 1
  // }

  // logoLetters.forEach(function (a, i) {
  //   a.classList = 'ss0' + Math.floor(Math.random() * 3)
  //   a.addEventListener('mousemove', function (e) {
  //     touchLettersA[i] = 0
  //     if (i - 1 >= 0) {
  //       touchLettersA[i - 1] = Math.min(0.5, touchLettersA[i - 1])
  //     }
  //     if (i - 2 >= 0) {
  //       touchLettersA[i - 2] = Math.min(0.75, touchLettersA[i - 2])
  //     }
  //     if (i + 1 <= 12) {
  //       touchLettersA[i + 1] = Math.min(0.5, touchLettersA[i + 1])
  //     }
  //     if (i + 2 <= 12) {
  //       touchLettersA[i + 2] = Math.min(0.75, touchLettersA[i + 2])
  //     }

  //     if (i - 1 >= 0) {
  //       touchLettersT[i - 1] && clearTimeout(touchLettersT[i - 1])
  //       touchLettersT[i - 1] = setTimeout(function () {
  //         touchLettersA[i - 1] = 1
  //       }, params.touchDelay)
  //     }
  //     if (i - 2 >= 0) {
  //       touchLettersT[i - 2] && clearTimeout(touchLettersT[i - 2])
  //       touchLettersT[i - 2] = setTimeout(function () {
  //         touchLettersA[i - 2] = 1
  //       }, params.touchDelay)
  //     }

  //     if (i + 1 <= 12) {
  //       touchLettersT[i + 1] && clearTimeout(touchLettersT[i + 1])
  //       touchLettersT[i + 1] = setTimeout(function () {
  //         touchLettersA[i + 1] = 1
  //       }, params.touchDelay)
  //     }
  //     if (i + 2 <= 12) {
  //       touchLettersT[i + 2] && clearTimeout(touchLettersT[i + 2])
  //       touchLettersT[i + 2] = setTimeout(function () {
  //         touchLettersA[i + 2] = 1
  //       }, params.touchDelay)
  //     }

  //     touchLettersT[i] && clearTimeout(touchLettersT[i])
  //     touchLettersT[i] = setTimeout(function () {
  //       touchLettersA[i] = 1
  //     }, params.touchDelay)
  //   })
  // })

  var offsetSwitch = 0;

  function offsetStacks(off) {
    console.log("switch");
    offsetSwitch = 1;
  }

  var scrollManager = {};

  w.scrollConst = 0;
  // var nw.scrollConst = 0;
  // var scrollPos = 4500000;

  var initScrollVal = 499;

  scrollManager.nullPos = initScrollVal * windowManager.sh + windowManager.so;
  scrollManager.reO = 0;

  scrollManager.adjust = 0;
  scrollManager.adjustTo = 0;

  scrollManager.reset = function () {
    let diff =
      (-windowManager.so + scrollManager.nullPos) / windowManager.sh -
      (-windowManager.so + scroller.scrollTop) / windowManager.sh;
    console.log("reset diff", diff);
    // scrollManager.reO = scrollManager.reO + diff;
  };

  var scrollPos = scrollManager.nullPos;
  var scroller = q("#scroller");
  w.scroller = scroller;
  scroller.setAttribute("dir", "ltr");

  var scrollTimeout = 0;
  var scrollAc = 0;

  var scrollRep;
  var shiftRep;

  w.stackConst = 0;

  scroller.scrollTop = scrollPos;
  w.scrollConst = (-windowManager.so + scroller.scrollTop) / windowManager.sh;
  // w.tween.scroll = w.scrollConst + 0.5

  w.tween = {
    scroll: w.scrollConst + 0.5,
    mouseX: windowManager.iw2 / windowManager.iw,
    mouseY: windowManager.ih2 / windowManager.ih,
  };

  shiftRep = floor(w.scrollConst);
  scrollRep = floor(w.scrollConst);
  w.scroller = scroller;

  var prevScroll = 0;

  w.allScroll = 1;

  w.p3 = 0;
  w.prevIx = 999;

  // var vStacks = [0, 0]
  w.aStacks = [0, 0];

  var animating = 0;

  scroller.focus();

  if (!originalPath.single) {
    scroller.addEventListener(
      "scroll",
      function (e) {
        // console.log(scroller.scrollTop);
        if (w.allScroll) {
          if (scrollAc && !w.playAC) {
            w.scrollConst =
              scrollManager.reO +
              (-windowManager.so + scroller.scrollTop) / windowManager.sh;
          }

          if (w.playAC) {
            w.stackConst =
              ((-windowManager.so + scroller.scrollTop) / windowManager.sh -
                w.scrollConst) *
              0.15;
          }
          if (scrollAc) {
            if (w.shaderCancel) {
              w.shaderCancel = 0;
              shaderManager.update(0);
            }
          }
        }

        // if (prevScroll != scroller.scrollTop) {
        //   console.log(scroller.scrollTop)
        // }

        prevScroll = scroller.scrollTop;

        window.clearTimeout(scrollManager.endTO);
        window.clearTimeout(scrollManager.adjustTo);

        scrollManager.endTO = setTimeout(function () {
          // console.log('stopped', w.scrollConst, round(w.scrollConst))
          if (d.querySelectorAll(".proj.if").length) {
            closeAllInfo();
          }
          // closeAllInfo()
          if (d.querySelectorAll(".proj.ac").length) {
            d.querySelectorAll(".proj.ac:not(.feat)").forEach(function (a) {
              a.classList.remove("ac");
              a.querySelector(".btm > div > :first-child").classList.remove(
                "uvv"
              );
            });
          }
          if (animating && abs(w.scrollConst - round(w.scrollConst)) < 0.02) {
            // console.log('scrolling stopped on project', w.p3)
            var sIx =
              abs(w.aStacks[0][1]) < abs(w.aStacks[1][1])
                ? w.aStacks[0][0]
                : w.aStacks[1][0];
            // console.log('scrolling stopped on project', sIx)

            // currentProject(originalPath.single ? 0 : sIx)

            stacksDOM[sIx].classList.add("ac");
            // stacksDOM[sIx].classList.add('uvv')
            stacksDOM[sIx]
              .querySelector(".btm > div > :first-child")
              .classList.add("uvv");

            // console.log('scrolling stopped on project', sIx)
            // console.log(
            //   'attempt reset',
            //   round(w.scrollConst) - w.centerConst,
            //   w.acStacks.length,
            //   Math.floor(
            //     (round(w.scrollConst) - w.centerConst) / w.acStacks.length
            //   )
            // )
            // let re = Math.floor(
            //   (round(w.scrollConst) - w.centerConst) / w.acStacks.length
            // )
            // if (re) {
            //   w.allScroll = 0
            //   let newScrollTop =
            //     (round(w.scrollConst) - w.acStacks.length * re) *
            //     windowManager.sh

            //   scroller.scrollTop = newScrollTop

            //   w.scrollConst =
            //     scrollManager.reO +
            //     (-windowManager.so + newScrollTop) / windowManager.sh

            //   w.tweenscroll = w.scrollConst - 0.001

            //   w.allScroll = 1
            // }
          } else {
            scrollManager.adjustTo = setTimeout(function () {
              // console.log('scroll snap fallback', round(w.scrollConst))
              scroller.scrollTop = round(w.scrollConst) * windowManager.sh;
            }, 200);
          }
        }, 132);
      },
      { passive: true }
    );
  }

  setTimeout(function () {
    scrollAc = 1;
  }, 750);

  var prevShiftRep = initScrollVal - 1;
  w.prevShiftRep = prevShiftRep;

  var average_fps = 0;
  var average_fps_c = 0;

  var hue = 0;

  d.querySelectorAll('[area-label="next"]').forEach(function (a) {
    a.addEventListener("click", function () {
      console.log("next click");
    });
  });

  stackManager(
    stacksDOM,
    Stacks,
    TweenMax,
    Power3,
    windowManager.iw,
    vw,
    vh,
    max,
    playPromise,
    params
  );

  function resize() {
    windowManager.resize();

    if (selectAll("#scrollHolder div").length) {
      selectAll("#scrollHolder div").forEach(function (a) {
        a.style.height = windowManager.sh * (w.playAC ? 2 : 1) + "px";
      });
    }

    if (cam && Renderer) {
      cam.aspect = windowManager.iw / windowManager.ih;
      cam.updateProjectionMatrix();
      Renderer.forEach(function (a) {
        a.setSize(windowManager.iw, windowManager.ih);
      });
    }
  }

  var wght_min = 0;
  var wght_max = 250;
  var logo = { x: 0, y: 0, s1: 1 };

  var lettersCur = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  var letter_sound = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  var audio_status = false;

  w.initScale = { s: 0.7, o: 0 };
  w.logoScale = 0;

  function initScaleUpdate(stack, end = 0) {
    stack.children.forEach(function (sTile, i) {
      let s = w.initScale.s;
      sTile.scale.set(end ? 1 : s, end ? 1 : s, 1);
      sTile.ogO.o = end ? 1 : (s - 0.7) / 0.3;
      w.logoScale = sTile.ogO.o;
      let r = end ? 9 : round(9 * sTile.ogO.o);
    });
  }

  if (supportManager.webgl) {
    resize();
    import("./components/animation/animationLoop").then((module) => {
      scroller.focus();
      module.default(); // animate()
      resize();
      animating = 1;

      setTimeout(function () {
        activateFaves();

        !careers && document.body.removeAttribute("data-b");

        document.querySelector(".uv.close").removeAttribute("style");
        currentProject(
          originalPath.single
            ? 0
            : abs(w.aStacks[0][1]) < abs(w.aStacks[1][1])
            ? w.aStacks[0][0]
            : w.aStacks[1][0]
        );
      }, 500);

      setTimeout(function () {
        Stacks[0][0].element.classList.add("ac");
        // Stacks[0][0].element.classList.add('uvv')
        Stacks[0][0].element
          .querySelector(".btm > div > :first-child")
          .classList.add("uvv");
      });

      setTimeout(function () {
        TweenMax.to(w.initScale, 1.5, {
          s: 1,
          ease: Power3.easeOut,
          onStart: function () {
            state.scaleAnimating = 1;
            // console.log(stacks[0][1]);
          },
          onUpdate: function () {
            initScaleUpdate(Stacks[0][1]);
          },
          onComplete: function () {
            state.scaleAnimating = 0;
            initScaleUpdate(Stacks[0][1], 1);
            TweenMax.killTweensOf(w.initScale);
          },
        });
      }, 500);

      document
        .querySelectorAll(".uv,.ext" + (supportManager.touch ? "" : ",.hv"))
        .forEach(function (a) {
          a.addEventListener("mouseenter", function (e) {
            clearTimeout(e.target.timeout);
            e.target.classList.add("calt");
          });
          a.addEventListener("mouseleave", function (e) {
            let classes = e.target.classList;
            clearTimeout(e.target.timeout); // maybe not needed
            e.target.timeout = setTimeout(function () {
              e.target.classList.remove("calt");
              if (classes.contains("ss1h")) {
                classes.remove("ss1h");
                classes.add("ss2h");
              } else if (classes.contains("ss2h")) {
                classes.remove("ss2h");
                classes.add("ss1h");
              } else {
                let iEl = e.target.querySelector("i");
                if (iEl) {
                  let text = iEl.innerHTML;
                  let matches = text.match(/[\u200B\u200C]+/g);
                  if (matches) {
                    let match = matches[0]
                      .replace(/\u200B/g, "0")
                      .replace(/\u200C/g, "1");
                    text = text.replace(/\u200B|\u200C/g, "");
                    // console.log("calt match", text, match);
                    let r = variableFontShift(text, match);
                    e.target.innerHTML = e.target.innerHTML.replace(
                      /[\u200B\u200C]+/g,
                      r
                    );
                  } else {
                    // console.log("calt no match", text);
                    let r = variableFontShift(text);
                    e.target.innerHTML = e.target.innerHTML.replaceAll(
                      text,
                      r + text + r
                    );
                  }
                } else {
                  // console.log("calt no match", text);
                  let tagret = e.target.querySelector("span") || e.target;
                  let text = tagret.innerHTML;
                  let r = variableFontShift(text);
                  tagret.innerHTML = tagret.innerHTML.replaceAll(
                    text,
                    r + text + r
                  );
                }
              }
            }, 425);
          });
        });
    });
    // }, 500)
  }
  resize();

  function variableFontShift(text, prev = "") {
    let r = randomiseFont(prev);
    // let test = r.replace(/\u200B/g, '0').replace(/\u200C/g, '1')
    return r;
  }

  function randomiseFont(prev = "") {
    var a = "";
    for (let i = 0; i < Math.floor(Math.random() * 5); i++) {
      a += Math.floor(Math.random() * 2).toString();
    }
    if (prev != a) {
      let b = "";
      for (const c of a) {
        b += ["&#8203;", "&#8204;"][parseInt(c)];
      }
      return b;
    } else {
      return randomiseFont(prev);
    }
  }

  document.querySelectorAll(".btm .tile-title").forEach(function (g) {
    if (g.querySelector(".uv:not(.sp)")) {
      g.style.width =
        g.querySelector(".sp").offsetWidth +
        g.querySelector(".uv:not(.sp)").offsetWidth +
        "px";
    } else {
      g.style.width = "0px";
    }
  });

  window.addEventListener("orientationchange", function (e) {
    setTimeout(function () {
      resize();
    }, 500);
  });

  window.addEventListener("resize", resize, false);

  document.onclick = function () {
    w.speed1 = Math.max(...lettersCur) * params.clickStrength;
    setTimeout(function () {
      w.speed1 = 0;
    }, params.clickDelay);
  };

  window.onload = function () {
    "use strict";

    var soundAllowed = function (stream) {
      window.persistAudioStream = stream;

      var audioContent = new AudioContext();
      var audioStream = audioContent.createMediaStreamSource(stream);
      var analyser = audioContent.createAnalyser();
      audioStream.connect(analyser);
      analyser.fftSize = 32;

      var frequencyArray = new Uint8Array(analyser.frequencyBinCount);

      audio_status = true;

      var doDraw = function () {
        requestAnimationFrame(doDraw);
        analyser.getByteFrequencyData(frequencyArray);

        for (var i = 0; i < 13; i++) {
          var wght_full = frequencyArray[i * 3 + 5] * 0.45;
          var wght = (wght_full / wght_max) * wght_max;

          letter_sound[i] = wght * params.soundStrength;
        }
      };
      doDraw();
    };

    var soundNotAllowed = function (error) {
      console.log(error);
    };
  };
  var workbox = document.querySelector("#work");
  w.workbox = workbox;
  document.querySelector(".work")?.addEventListener("click", function () {
    clearTimeout(workbox.timeout);
    if (document.body.classList == "work") {
      closeWork();
      closeAny();
      notice2.innerHTML = filterManager.restore;
      filterManager.restore && doFilter();
    } else {
      openAny();
      workbox.style = "display:block";
      resizeManager.resizeWorkTable();
      shaderManager.update(1);
      startScaleUpdate(0.8);

      workbox.timeout = setTimeout(function () {
        filterManager.activeFilters.length && unfilter(1);
        document.body.classList = "work";
        document.body.dataset.b = 1;
      }, 100);
    }
  });

  function closeAny() {
    shaderManager.update(0);
    d.body.removeAttribute("data-b");
    d.body.removeAttribute("data-h");
    startScaleUpdate(1);
    showAllFilter();
  }

  w.closeAny = closeAny;

  function closeFeat() {
    window.feat = 0;
    document.body.classList.remove("feat");
    // startScaleUpdate(0.5, w.allScale.p, 1)
    startScaleUpdate(0.5, 0, 1);
    // reClass([document.querySelector('.proj.feat')], 'uvv', 'fuv')
    q("#btns .close").classList.remove("uvv");
    q("#btns .menu").classList.add("nd");
    q("#btns .menu").classList.add("uvv");

    document.querySelectorAll(".proj.feat .uf").forEach(function (uvv) {
      uvv.classList.remove("uf");
    });
  }

  function openAny() {
    TweenMax.killTweensOf(w.allScale);
    d.body.dataset.b = 1;
    clearBoxTimeouts();
    closeAllInfo();
    hideAllFilter();
  }

  function closeAllInfo() {
    w.rotation = 1;
    shaderManager.update(0);
    stacksDOM.forEach(function (stack) {
      if (stack.classList.contains("if")) {
        stack.classList.remove("if");
        stack
          .querySelector(".btm > div > :nth-child(3)")
          .classList.remove("uvv");
        stack.querySelector(".btm > div > :first-child").classList.add("uvv");
      }
    });
  }

  function closeWork() {
    clearTimeout(workbox.timeout);
    document.body.classList = "";
    cancelAutoscroll();
    workbox.timeout = setTimeout(function () {
      workbox.style = "display:none";
    }, 900);
  }

  var artistsbox = document.querySelector("#artists");
  w.artistsbox = artistsbox;
  document.querySelector(".artists")?.addEventListener("click", function () {
    clearTimeout(artistsbox.timeout);
    if (!scaleVolManager.stack) {
      if (document.body.classList == "artists") {
        document.body.classList = "";
        closeAny();
        cancelAutoscroll();
        artistsbox.timeout = setTimeout(function () {
          artistsbox.style = "display:none";
        }, 900);
        notice2.innerHTML = filterManager.restore;
        filterManager.restore && doFilter();
      } else {
        openAny();
        artistsbox.style = "display:flex";
        artistsbox.timeout = setTimeout(function () {
          filterManager.activeFilters.length && unfilter(1);
          document.body.classList = "artists";
          document.body.dataset.b = 1;
          shaderManager.update(1, 1);
          w.autoScroll = 1;
        }, 100);
      }
    }
  });

  artistsbox.querySelectorAll(".list a[href]").forEach((link) => {
    link.addEventListener(
      "click",
      (e) => {
        e.preventDefault();

        document.body.classList.add("leaving");

        console.log("artist link", e.target.href);
        startScaleUpdate(0, 0, 0, e.target.href);
      },
      false
    );
  });

  var studiobox = document.querySelector("#studio");
  w.studiobox = studiobox;
  document.querySelector(".studio")?.addEventListener("click", function () {
    clearTimeout(studiobox.timeout);
    if (!scaleVolManager.stack) {
      if (document.body.classList == "studio") {
        document.body.classList = "";
        closeAny();
        cancelAutoscroll();
        studiobox.timeout = setTimeout(function () {
          studiobox.style = "display:none";
        }, 900);
        notice2.innerHTML = filterManager.restore;
        filterManager.restore && doFilter();
      } else {
        openAny();
        studiobox.style = "display:flex";
        studiobox.timeout = setTimeout(function () {
          filterManager.activeFilters.length && unfilter(1);
          document.body.classList = "studio";
          document.body.dataset.b = 1;
          shaderManager.update(1, 1);
          w.autoScroll = 1;
        }, 100);
      }
    }
  });

  var careerBox = document.querySelector("#careers");
  w.careerBox = careerBox;

  setupCareers();

  document.body.querySelectorAll("a[href='/careers']").forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      window.history.replaceState({}, "", "/careers");
      openCareers();
    });
  });

  // window.addEventListener('popstate', (e) => {
  //   console.log('pop', e)
  // })

  function openCareers() {
    console.log(`open careers`);
    if (!scaleVolManager.stack) {
      openAny();
      studiobox.style = "display:flex";
      careerBox.style = "display:flex";
      careerBox.timeout = setTimeout(function () {
        filterManager.activeFilters.length && unfilter(1);
        document.body.classList = "studio careers";
        document.body.dataset.b = 1;
        shaderManager.update(1, 1);
        w.autoScroll = 1;
      }, 100);
    }
  }

  var sharebox = document.querySelector("#share");
  w.sharebox = sharebox;
  document
    .querySelector(".share-button")
    .addEventListener("click", function () {
      clearTimeout(sharebox.timeout);
      if (document.body.classList == "share") {
        document.body.classList.remove("share");
        closeAny();
        cancelAutoscroll();
        sharebox.timeout = setTimeout(function () {
          sharebox.style = "display:none";
        }, 900);
      } else {
        openAny();
        sharebox.style = "display:block";
        sharebox.timeout = setTimeout(function () {
          document.body.classList.add("share");
          // w.shaderCancel = 0
          // w.shaderStrength = 0
          // w.shaderOpacity = 1
          shaderManager.update(1);
        }, 100);
      }
    });

  document.querySelectorAll("#share input,#work input").forEach(function (a) {
    a.addEventListener(
      "click",
      function (e) {
        let input = e.target;
        input.select();
        input.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(input.value);

        let parent = input.parentElement.querySelector(".copy-link");
        parent.querySelectorAll("a")[0].classList.remove("uvv");
        parent.querySelectorAll("a")[1].classList.add("uvv");
        clearTimeout(parent.timeout);
        parent.timeout = setTimeout(function () {
          parent.querySelectorAll("a")[1].classList.remove("uvv");
          parent.querySelectorAll("a")[0].classList.add("uvv");
        }, 2000);
      },
      supportManager.passive
    );
  });

  document.querySelectorAll(".copy-link").forEach(function (a) {
    a.addEventListener(
      "click",
      function (e) {
        let parent = e.target;
        while (!parent.classList.contains("copy-link")) {
          parent = parent.parentElement;
        }
        console.log(parent.dataset.for);
        let input = document.querySelector("#" + parent.dataset.for);
        input.select();
        input.setSelectionRange(0, 99999); /* For mobile devices */
        input.blur();
        navigator.clipboard.writeText(input.value);
        parent.querySelectorAll("a")[0].classList.remove("uvv");
        parent.querySelectorAll("a")[1].classList.add("uvv");
        clearTimeout(parent.timeout);
        parent.timeout = setTimeout(function () {
          parent.querySelectorAll("a")[1].classList.remove("uvv");
          parent.querySelectorAll("a")[0].classList.add("uvv");
        }, 2000);
      },
      supportManager.passive
    );
  });

  document.querySelectorAll("#logo,.close").forEach(function (a) {
    a.addEventListener(
      "click",
      function () {
        if (supportManager.touch) {
          closeBtns();
          setTimeout(function () {
            closeBtns();
          }, 10);
        }
        if (
          document.body.classList.contains("share") ||
          document.body.classList.contains("careers")
        ) {
          document.body.classList.remove("share");
          document.body.classList.remove("careers");
          window.history.replaceState({}, "", "/");
        } else {
          showAllFilter();
          if (
            document.body.classList.contains("work") ||
            document.body.classList.contains("studio")
          ) {
            notice2.innerHTML = filterManager.restore;
            filterManager.restore && doFilter();
          }
          document.body.classList = "";
          // w.shaderOpacity = 0
          // w.shaderStrength = 0
          // w.shaderCancel = 1
          shaderManager.update(0);
          cancelAutoscroll();
          [workbox, artistsbox, studiobox, sharebox].forEach(function (a) {
            clearTimeout(a.timeout);
            a.timeout = setTimeout(function () {
              a.style = "display:none";
            }, 900);
          });
          closeAny();
          scaleVolManager.pauseStack(1);
          if (window.feat) {
            closeFeat();
            d.body.dataset.b = 1;
          }
        }
      },
      supportManager.passive
    );
  });

  const clearBoxTimeouts = function () {
    [
      window.workbox,
      window.artistsbox,
      window.studiobox,
      window.sharebox,
    ].forEach(function (box) {
      clearTimeout(box.timeout);
      box.style = "display:none";
    });
  };

  w.clearBoxTimeouts = clearBoxTimeouts();

  if (window.location.pathname.includes("/careers")) {
    openAny();
    openCareers();
  }

  function activateFaves() {
    document.querySelectorAll(".fave").forEach(function (a) {
      a.addEventListener("click", function (e) {
        const tile = e.target.dataset.f;
        if (w.faves.includes(tile)) {
          console.log("active, remove");
          w.faves = w.faves.filter((el) => el !== tile);
          document.querySelectorAll(`[data-f="${tile}"]`).forEach(function (f) {
            f.classList.remove("ac");
            console.log("from", f);
          });
        } else {
          console.log("not active, push");
          w.faves.push(tile);
          document.querySelectorAll(`[data-f="${tile}"]`).forEach(function (f) {
            f.classList.add("ac");
            console.log("from", f);
          });
        }
        // cookie disabled
        // var cookie = ['w.faves=', w.faves.join(','), ';'].join('')
        // document.cookie = cookie
        checkFaves();
      });
    });
  }

  function isEnum(x) {
    for (var p in x) return !0;
    return !1;
  }

  function checkFaves() {
    if (w.faves.length) {
      q(".share-work").classList.add("uvv");
      shareManager.w();
    } else {
      q(".share-work").classList.remove("uvv");
    }
  }

  checkFaves();

  document.querySelectorAll(".tr p").forEach(function (a) {
    a.addEventListener("mouseenter", function (e) {
      slideOut(e.target);
    });

    a.addEventListener("mouseleave", function (e) {
      slideIn(e.target);
    });
  });

  document.querySelectorAll(".tr").forEach(function (a) {
    if (!supportManager.touch) {
      a.addEventListener("mouseenter", function (e) {
        moveTo(e.target);
      });
    }
    a.addEventListener("click", function (e) {
      var target = e.target;
      console.log("WORK CLICK", target.nodeName, target.parentElement);
      if (target.nodeName == "P" || target.nodeName == "SPAN") {
        if (target.nodeName == "SPAN") {
          target = target.parentElement;
        }
        var stackPos = target.dataset.p;
        var toSlide = target.dataset.s;

        if ((stackPos || stackPos == 0) && (toSlide || toSlide == 0)) {
          var stack = Stacks[stackPos][1];

          stack.children[toSlide].slideX.x = 0;
          stack.children[toSlide].slideY.y = 0;
          stack.children[toSlide].slideO.o = 0;

          if (stack.curTop != toSlide) {
            var f;
            var children = [];
            for (f = 0; f < stack.children.length; f++) {
              children.push(f);
            }
            while (children[0] != stack.curTop) {
              children.push(children.shift());
            }
            var switches = 0;
            while (children[0] != toSlide) {
              children.push(children.shift());
              switches++;
            }
            console.log("switches " + switches);
            for (f = 0; f < switches; f++) {
              setTimeout(function () {
                stackNext(stack, 0);
              }, 75 * f);
            }
          }
        }
      }
      if (!target.classList.contains("fave")) {
        // w.shaderOpacity = 0
        shaderManager.update(0);
        closeWork();
        closeAny();
      }
    });
  });

  // Back Up Html Version
  if (!supportManager.webgl) {
    var backup = create("div");
    backup.id = "backup";
    document.body.append(backup);
    var html = "";
    var z = 0;
    for (const [key, project] of Object.entries(window.AP.work)) {
      project.v.forEach(function (a, i) {
        if (a.video.id) {
          html +=
            '<div><div><div style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.videodelivery.net/' +
            a.video.id +
            '?preload=true" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div></div></div>';
        }
        //&controls=false
      });
    }
    backup.innerHTML = html;
  }

  function cancelAutoscroll() {
    w.autoScroll = 0;
    console.log("cancelAutoscroll");
    // console.log(w.scrollConst);
    w.scrollConst = round(w.scrollConst);
    w.scroller.scrollTop = w.scrollConst * windowManager.sh + windowManager.so;
    // c w.scrollConst * windowManager.ih;
  }

  setTimeout(function () {
    var data =
      navigator.platform +
      ", " +
      supportManager.browser +
      ", " +
      w.innerWidth +
      "x" +
      w.innerHeight +
      ", Gl " +
      supportManager.webgl +
      ", " +
      (supportManager.touch ? "touch" : "no touch") +
      ", " +
      average_fps / average_fps_c +
      "fps";
    gtag("event", data, {
      event_category: "AP",
      value: 1,
    });
  }, 3000);

  const filterEl = document.querySelector("#filter");

  function toggleFilter(toggle) {
    filterEl.classList[toggle]("uvv");
    document.querySelectorAll(".cr").forEach(function (by) {
      by.classList[toggle]("hd");
    });
    if (supportManager.touch) {
      window.removeEventListener("click", closeFilter);
    }
  }

  function closeFilter() {
    toggleFilter("remove");
  }

  if (supportManager.touch) {
    filterEl.addEventListener("click", function () {
      toggleFilter("add");
      setTimeout(function () {
        window.addEventListener("click", closeFilter);
      }, 10);
      clearTimeout(filterEl.timeout);
      filterEl.timeout = setTimeout(closeFilter, 2000);
    });
  } else {
    [
      ["enter", "add"],
      ["leave", "remove"],
    ].forEach(function (e) {
      filterEl.addEventListener(
        "mouse" + e[0],
        function () {
          toggleFilter(e[1]);
        },
        supportManager.passive
      );
    });
  }

  const btnsEl = document.querySelector("#btns");

  function toggleBtns(toggle) {
    if (!window.feat) {
      q("#btns > :not(.menu):not(.close)")?.length &&
        q("#btns > :not(.menu):not(.close)").forEach(function (a) {
          a.classList[toggle ? "add" : "remove"]("nd");
          a.classList[toggle ? "add" : "remove"]("uvv");
        });
      q(".menu").classList[toggle ? "remove" : "add"]("uvv");
    }
    if (supportManager.touch) {
      window.removeEventListener("click", closeBtns);
    }
  }

  function closeBtns() {
    toggleBtns(0);
  }

  if (originalPath.shared) {
    q(".menu").addEventListener("click", function () {
      (
        document.querySelector("html") || document.documentElement
      ).classList.add("leaving");

      closeAllInfo();
      hideAllFilter();

      q(
        "#btns .work,#btns .studio,#btns .featured,#btns .menu,.proj .uvv"
      ).forEach(function (a) {
        a.classList.remove("uvv");
        a.classList.remove("nd");
      });
      startScaleUpdate(0, 0, 0, "/");
    });
  } else {
    if (supportManager.touch) {
      btnsEl.addEventListener("click", function () {
        toggleBtns(1);
        setTimeout(function () {
          window.addEventListener("click", closeBtns);
        }, 10);
        clearTimeout(btnsEl.timeout);
        btnsEl.timeout = setTimeout(closeBtns, 2000);
      });
    } else {
      [
        ["enter", 1],
        ["leave", 0],
      ].forEach(function (e) {
        btnsEl.addEventListener(
          "mouse" + e[0],
          function () {
            toggleBtns(e[1]);
          },
          supportManager.passive
        );
      });
    }
  }

  window.feat = 0;
  window.clickAc = 1;

  function hideAllFilter() {
    if (!originalPath.artist) {
      d.querySelector("#filter")
        .querySelectorAll(".uvv")
        .forEach(function (uvv) {
          uvv.classList.add("hd");
        });
    }
  }

  function showAllFilter() {
    d.querySelector("#filter")
      .querySelectorAll(".hd")
      .forEach(function (uvv) {
        uvv.classList.remove("hd");
      });
    stackCredits(w.p3);
  }

  q(".featured")?.addEventListener("click", function () {
    if (window.clickAc || true) {
      window.clickAc = 0;
      body.dataset.scroll = 0;
      if (window.feat) {
        closeFeat();
        showAllFilter();
      } else {
        openAny();
        hideAllFilter();
        closeAllInfo();
        q("#btns .work,#btns .studio,#btns .featured,#btns .menu").forEach(
          function (a) {
            a.classList.remove("uvv");
            a.classList.remove("nd");
          }
        );
        q("#btns .close").classList.add("uvv");

        window.feat = 1;
        document.body.classList.add("feat");

        document.body
          .querySelectorAll(".proj:not(.feat)")
          .forEach(function (proj) {
            proj.classList.remove("ac");
            proj.classList.remove("if");
          });

        // startScaleUpdate(0.5, [ogStacks.length, ogStacks.length], 1)
        startScaleUpdate(0.5, 0, 1);
        var sIx =
          abs(w.aStacks[0][1]) < abs(w.aStacks[1][1])
            ? w.aStacks[0][0]
            : w.aStacks[1][0];

        // reClass([stacksDOM[sIx]], 'uvv', 'wuv')
      }
    }
  });

  function startScaleUpdate(scale, updateStacks = 0, back = 0, link = false) {
    TweenMax.killTweensOf(w.allScale);
    TweenMax.to(w.allScale, back ? 0.4 : 1, {
      s: scale,
      ease: back ? Linear.easeNone : Power3.easeOut,

      onUpdate: function () {
        scaleUpdate(w.acStacks, false);
        currentProject(originalPath.single ? 0 : currentProjectIndex());
        console.log(w.allScale.s);
        if (link) {
          w.logoScale = w.allScale.s;
        }
      },
      onComplete: function () {
        scaleUpdate(w.acStacks, scale);

        TweenMax.killTweensOf(w.allScale);

        if (link) {
          w.logoScale = 0;
          window.stopAnimation();
          window.location = link;
        }

        if (!window.feat && !d.body.classList.contains("work")) {
          stacksDOM[currentProjectIndex()]?.classList.add("ac");
          setTimeout(function () {
            d.body.removeAttribute("data-b");
            stacksDOM[currentProjectIndex()]?.classList.add("ac");
          }, 100);
          window.loopFeat = 0;
        }
        if (window.loopFeat) {
          document.querySelector(".proj.feat").classList.add("ac");
          setTimeout(function () {
            document
              .querySelectorAll(
                '.proj.feat .fuv,.proj.feat .uvv,.proj.feat [aria-label="next"]'
              )
              .forEach(function (uvv) {
                uvv.classList.remove("fuv");
                uvv.classList.add("nd", "uvv", "uf");
              });
          }, 10);
        }
        if (window.feat) {
          window.loopFeat = 1;
        }
        if (back) {
          startScaleUpdate(1);
        } else {
          d.querySelectorAll(".proj").forEach(function (proj) {
            proj.classList.remove("force-block");
          });
          if (!window.feat) {
            body.dataset.scroll = 1;
          }
        }
        currentProject(originalPath.single ? 0 : currentProjectIndex());
      },
    });
  }

  function reClass(stacks, from, to) {
    stacks.forEach(function (stack) {
      // if (stack.classList.contains(from)) {
      console.log(from + " stack found");
      // stack.classList.remove(from)
      // stack.classList.add(to)
      // stack.querySelectorAll('.uv').forEach(function (el) {
      //   el.classList.add('rnd')
      //   el.classList.add('nd')
      // })
      stack.querySelectorAll("." + from).forEach(function (el) {
        el.classList.remove(from);
        el.classList.add(to);
      });
      // setTimeout(function () {
      //   stack.querySelectorAll('.rnd').forEach(function (el) {
      //     el.classList.remove('rnd')
      //     el.classList.remove('nd')
      //   })
      // }, 100)
      // }
    });
  }

  w.allScale = { s: 1, o: 1, p: w.acStacks };

  function scaleUpdate(stacks, end) {
    // console.log()
    let isEnd = end != false;
    let s = w.allScale.s;
    stacks.forEach(function (nr, i) {
      let stack = w.allStacks[nr][1];
      stack.children.forEach(function (tile, i) {
        tile.scale.set(isEnd ? end : s, isEnd ? end : s, 1);
        tile.ogO.o = isEnd ? end : (s - 0.7) / 0.3;
      });
    });
  }
}

// window.setup = setup

if (/reels|artists/.test(originalPath.path[0])) {
  window.setup = setup;
} else {
  setup();
}
