import {
  DataTexture,
  FloatType,
  MathUtils,
  RGBFormat,
  ShaderMaterial,
  UniformsUtils,
  BufferGeometry,
  Mesh,
  Float32BufferAttribute,
  OrthographicCamera
} from 'three'
import { Pass } from 'three/examples/jsm/postprocessing/Pass.js'
import { FullScreenQuad } from './fsQuad'
import { DigitalGlitch } from './shader'

import { params } from '../setup/params'

// const _camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)
// const _geometry = new BufferGeometry()
// _geometry.setAttribute(
//   'position',
//   new Float32BufferAttribute([-1, 3, 0, -1, -1, 0, 3, -1, 0], 3)
// )
// _geometry.setAttribute('uv', new Float32BufferAttribute([0, 2, 0, 0, 2, 0], 2))

// class FullScreenQuad {
//   constructor(material) {
//     this._mesh = new Mesh(_geometry, material)
//   }

//   dispose() {
//     this._mesh.geometry.dispose()
//   }

//   render(renderer) {
//     renderer.render(this._mesh, _camera)
//   }

//   get material() {
//     return this._mesh.material
//   }

//   set material(value) {
//     this._mesh.material = value
//   }
// }

class APPass extends Pass {
  constructor(dt_size = 64) {
    super()

    if (DigitalGlitch === undefined)
      console.error('THREE.GlitchPass relies on DigitalGlitch')

    const shader = DigitalGlitch

    this.uniforms = UniformsUtils.clone(shader.uniforms)

    this.uniforms['tDisp'].value = this.generateHeightmap(dt_size)

    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader
    })

    this.fsQuad = new FullScreenQuad(this.material)

    this.goWild = true
    this.curF = 0
    this.generateTrigger()
  }

  render(renderer, writeBuffer, readBuffer /*, deltaTime, maskActive */) {
    var s = window.shaderStrength
    this.uniforms['tDiffuse'].value = readBuffer.texture
    // this.uniforms['seed'].value = params.seed * s //default seeding
    this.uniforms['byp'].value = params.byp

    if ((this.curF % this.randX == 0 || this.goWild == true) && false) {
      this.uniforms['amount'].value = Math.random() / 30
      this.uniforms['angle'].value = MathUtils.randFloat(-Math.PI, Math.PI)
      this.uniforms['seed_x'].value = MathUtils.randFloat(-1, 1)
      this.uniforms['seed_y'].value = MathUtils.randFloat(-1, 1)
      this.uniforms['distortion_x'].value = MathUtils.randFloat(0, 1)
      this.uniforms['distortion_y'].value = MathUtils.randFloat(0, 1)
      this.curF = 0
      // this.generateTrigger()
    } else if (this.curF % this.randX < this.randX / 5 || true) {
      // this.uniforms['amount'].value = (params.amount / 90) * s
      this.uniforms['angle'].value = Math.PI * params.angle
      this.uniforms['distortion_x'].value = params.distortion_x
      this.uniforms['distortion_y'].value = params.distortion_y
      this.uniforms['seed_x'].value = params.seed_x * s
      this.uniforms['seed_y'].value = params.seed_y * s
    } else if (this.goWild == false) {
      this.uniforms['byp'].value = 1
    }

    this.curF++

    if (this.renderToScreen) {
      renderer.setRenderTarget(null)
      this.fsQuad.render(renderer)
    } else {
      renderer.setRenderTarget(writeBuffer)
      if (this.clear) renderer.clear()
      this.fsQuad.render(renderer)
    }
  }

  generateTrigger() {
    this.randX = MathUtils.randInt(120, 240)
  }

  generateHeightmap(dt_size) {
    const data_arr = new Float32Array(dt_size * dt_size * 3)
    const length = dt_size * dt_size

    for (let i = 0; i < length; i++) {
      const val = MathUtils.randFloat(0, 1)
      data_arr[i * 3 + 0] = val
      data_arr[i * 3 + 1] = val
      data_arr[i * 3 + 2] = val
    }

    return new DataTexture(data_arr, dt_size, dt_size, RGBFormat, FloatType)
  }
}

export { APPass }
