function remove_duplicates(array_) {
  var ret_array = new Array();
  for (var a = array_.length - 1; a >= 0; a--) {
    for (var b = array_.length - 1; b >= 0; b--) {
      if (array_[a] == array_[b] && a != b) {
        delete array_[b];
      }
    }
    if (array_[a] != undefined) ret_array.push(array_[a]);
  }
  return ret_array;
}

function array_remove(array, removal) {
  for (var i = 0; i < array.length; i++) {
    if (array[i] === removal) {
      array.splice(i, 1);
    }
  }
  return array;
}

function closest(array, goal) {
  var a = array.reduce(function (prev, curr) {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
  return [a, Math.abs(a - goal)];
}

export { remove_duplicates, array_remove, closest };
