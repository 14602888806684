import {
  BufferGeometry,
  Mesh,
  Float32BufferAttribute,
  OrthographicCamera
} from 'three'

const _camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1)
const _geometry = new BufferGeometry()
_geometry.setAttribute(
  'position',
  new Float32BufferAttribute([-1, 3, 0, -1, -1, 0, 3, -1, 0], 3)
)
_geometry.setAttribute('uv', new Float32BufferAttribute([0, 2, 0, 0, 2, 0], 2))

class FullScreenQuad {
  constructor(material) {
    this._mesh = new Mesh(_geometry, material)
  }

  dispose() {
    this._mesh.geometry.dispose()
  }

  render(renderer) {
    renderer.render(this._mesh, _camera)
  }

  get material() {
    return this._mesh.material
  }

  set material(value) {
    this._mesh.material = value
  }
}

export { FullScreenQuad }
