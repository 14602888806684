import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

// import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

import { HorizontalBlurShader } from 'three/examples/jsm/shaders/HorizontalBlurShader.js'
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js'
// import { BasicShader } from 'three/examples/jsm/shaders/BasicShader'
// import { BasicShader } from 'three/examples/jsm/shaders/DigitalGlitch.js'
//import { DigitalGlitch } from "three/examples/jsm/shaders/DigitalGlitch.js";
import { APPass } from '../custom/pass'
import { DigitalGlitch } from '../custom/shader'

import { TweenMax, Power1 } from 'gsap/gsap-core'

import Renderer from '../setup/renderer'
import supportManager from '../managers/supportManager'
import { windowManager } from '../globals/globals'
import scenes from '../setup/scenes'
import camera from '../setup/camera'
import unit8Tex from '../custom/unit8Array'

const w = window

Renderer[2].addPass(new RenderPass(scenes[0], camera))

// if (!supportManager.antialias) {
//   import('three/examples/jsm/shaders/FXAAShader.js').then((FXAAShader) => {
//     const fxaaPass = new ShaderPass(FXAAShader)
//     fxaaPass.material.uniforms['resolution'].value.x =
//       1 / (windowManager.iw * 2)
//     fxaaPass.material.uniforms['resolution'].value.y =
//       1 / (windowManager.ih * 2)
//     Renderer[2].addPass(fxaaPass)
//     Renderer[2].render()
//   })
// }

var hblur = new ShaderPass(HorizontalBlurShader)
hblur.material.uniforms.h = 0
Renderer[2].addPass(hblur)
// Renderer[2].render()

var vblur = new ShaderPass(VerticalBlurShader)
vblur.material.uniforms.v = 0
Renderer[2].addPass(vblur)
// Renderer[2].render()

var distort = new ShaderPass(DigitalGlitch)
w.distort = distort
// vblur.material.uniforms.v = 0
Renderer[2].addPass(distort)
Renderer[2].render()

// var distort = new APPass()

// basic.material.uniforms.tDiffuse.v = readBuffer.texture
// basic.material.uniforms.seed.v = Math.random() //default seeding
// basic.material.uniforms.byp.v = 0

// basic.material.uniforms.amount.v = Math.random() / 30
// basic.material.uniforms.angle.v = threeMath.randFloat(-Math.PI, Math.PI)
// basic.material.uniforms.seed_x.v = threeMath.randFloat(-1, 1)
// basic.material.uniforms.seed_y.v = threeMath.randFloat(-1, 1)
// basic.material.uniforms.distortion_x.v = threeMath.randFloat(0, 1)
// basic.material.uniforms.distortion_y.v = threeMath.randFloat(0, 1)

// Renderer[2].addPass(apPass)
// Renderer[2].render()

// const shaderTween = {
//   o: 0,
//   s: 0
// }

var seed_xy = [0, 0, 0]

function update(opacity, strength = 0) {
  // console.log('shaderManager req')
  if (shaderManager.opactiy != opacity || shaderManager.strength != strength) {
    if (strength) {
      distort.material.uniforms.tDisp = {
        value: unit8Tex(4)
      }
      distort.material.uniforms.seed = {
        value: [-1, 1][Math.round(Math.random(0, 1))]
      }
      seed_xy = [
        [-1, 1][Math.round(Math.random(0, 1))] * 0.25,
        [-1, 1][Math.round(Math.random(0, 1))] * 0.25
      ]
      // distort.material.uniforms.seed_x = {
      //   value: Math.random(0, 2) - 1 / 10
      // }
      // distort.material.uniforms.seed_y = {
      //   value: Math.random(0, 2) - 1 / 10
      // }
    }
    TweenMax.killTweensOf(shaderManager)
    TweenMax.to(shaderManager, 1, {
      opacity: opacity,
      strength: strength,
      ease: Power1.easeInOut,
      onStart: function () {
        // shaderManager.active =
        //   shaderManager.active || strength || !supportManager.antialias
        shaderManager.active = shaderManager.active || strength
      },
      onUpdate: function () {
        // console.log('shaderManager update')
        hblur.material.uniforms.h = {
          value: ((shaderManager.strength * 1.5) / windowManager.iw) * 1.5
        }
        vblur.material.uniforms.v = {
          value: ((shaderManager.strength * 1.53) / windowManager.ih) * 1.5
        }
        distort.material.uniforms.seed_x = {
          value: shaderManager.strength * seed_xy[0]
        }
        distort.material.uniforms.seed_y = {
          value: shaderManager.strength * seed_xy[1]
        }
      },
      onComplete: function () {
        // console.log('shaderManager updated')
        TweenMax.killTweensOf(shaderManager)
        shaderManager.opactiy = opacity
        shaderManager.strength = strength
        // shaderManager.active = strength || !supportManager.antialias
        shaderManager.active = strength
      }
    })
  }
}

const shaderManager = {
  // active: !supportManager.antialias,
  active: false,
  strength: 0,
  opacity: 0,
  update: update
}

export default shaderManager
